import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: SostereoServicesModule
})
export class MetricsService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient,
              @Inject('environment') private environment) {
  }

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/metrics/${this.apiVersion}/metrics/${id}`;
    return this.http.get<any>(url);
  }
}

import { Component, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'app-rotating-text',
  templateUrl: './rotating-text.component.html',
  styleUrls: ['./rotating-text.component.scss'],
})
export class RotatingTextComponent implements AfterViewInit, OnDestroy {
  @Input() options: Array<string>;
  @Input() section: String;

  public words: any = document.getElementsByClassName('word');
  public wordArray: Array<any> = [];
  public currentWord = 0;
  public source = interval(1500);
  public subscribe = this.source.subscribe(() => this.changeWord());

  splitLetters(word) {
    const content = word.innerHTML;
    word.innerHTML = '';
    const letters = [];
    for (let i = 0; i < 1; i++) {
      const letter = document.createElement('span');
      letter.className = 'letter';
      letter.innerHTML = content;
      word.appendChild(letter);
      letters.push(letter);
    }
    this.wordArray.push(letters);
  }

  changeWord() {
    const cw = this.wordArray[this.currentWord];
    const nw =
      this.currentWord === this.words.length - 1
        ? this.wordArray[0]
        : this.wordArray[this.currentWord + 1];
    for (let i = 0; i < cw.length; i++) {
      this.animateLetterOut(cw, i);
    }
    for (let i = 0; i < nw.length; i++) {
      nw[i].className = 'letter behind';
      nw[0].parentElement.style.opacity = 1;
      this.animateLetterIn(nw, i);
    }
    this.currentWord = this.currentWord === this.wordArray.length - 1 ? 0 : this.currentWord + 1;
  }

  animateLetterOut(cw, i) {
    setTimeout(function () {
      cw[i].className = 'letter out';
    }, i * 80);
  }

  animateLetterIn(nw, i) {
    setTimeout(function () {
      nw[i].className = 'letter in';
    }, 340 + i * 80);
  }

  ngAfterViewInit() {
    this.words[this.currentWord].style.opacity = 1;
    for (let i = 0; i < this.words.length; i++) {
      this.splitLetters(this.words[i]);
    }
    this.changeWord();
  }

  ngOnDestroy() {
    setTimeout(() => this.subscribe.unsubscribe());
  }
}

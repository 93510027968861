export const refsArray = [
  {
    genre: 'Electronic Pop',
    description: 'Catchy rap, groovy bass-line, horns',
    link: 'https://www.youtube.com/watch?v=K6BRna4_bmg',
    cover: '/assets/images/common-refs/edamame.jpg',
    song: 'Edamame',
    artist: 'bbno$, Rich Brian',
    defaultBackGroundColor: '#263e54',
    defaultTextColor: '#d9f1f3',
    backgroundColor: '#788991',
    titleColor: 'white',
    paragraphColor: '#e5ecee',
  },
  {
    genre: 'Alternative Hip Hop',
    description: 'Driving hip hop beat, confident mood, elegant horns',
    link: 'https://www.youtube.com/watch?v=NUC2EQvdzmY',
    cover: '/assets/images/common-refs/nobody_speak.jpeg',
    song: 'Nobody Speak',
    artist: 'Dj Shadow, Run the Jewels',
    defaultBackGroundColor: '#d9f1f3',
    defaultTextColor: '#263e54',
    backgroundColor: '#595657',
    titleColor: '#fcfbfa',
    paragraphColor: '#dad4d3',
  },
  {
    genre: 'Orchestral Pop',
    description: 'Inspiring & victorious strings+horns build, organic drumline',
    link: 'https://www.youtube.com/watch?v=lmc21V-zBq0',
    cover: '/assets/images/common-refs/run-boy-run.jpeg',
    song: 'Run Boy Run',
    artist: 'Woodkid',
    defaultBackGroundColor: '#131f2a',
    defaultTextColor: 'white',
    backgroundColor: '#05080b',
    titleColor: '#fefeff',
    paragraphColor: '#c9c6c9',
  },
  {
    genre: 'Pop',
    description: 'Female Empowerment Lyrics, unusually fun kicks',
    link: 'https://www.youtube.com/watch?v=Dh-ULbQmmF8',
    cover: '/assets/images/common-refs/level-up.png',
    song: 'Level Up',
    artist: 'Ciara',
    defaultBackGroundColor: '#263e54',
    defaultTextColor: '#d9f1f3',
    backgroundColor: '#86a6c5',
    titleColor: '#0d1014',
    paragraphColor: '#2d3c4d',
  },
  {
    genre: 'Reggaeton',
    description: "Fast tempo & catchy, doesn't take itself seriously",
    link: 'https://www.youtube.com/watch?v=Cr8K88UcO0s',
    cover: '/assets/images/common-refs/titi.png',
    song: 'Tití Me Preguntó',
    artist: 'Bad Bunny',
    defaultBackGroundColor: '#d9f1f3',
    defaultTextColor: '#263e54',
    backgroundColor: '#8faeaa',
    titleColor: '#192231',
    paragraphColor: '#254145',
  },
  {
    genre: 'Pop/Hip Hop',
    description:
      'Strong middle eastern influence, dembow with thick bass, catchy lyrics, no boundaries',
    link: 'https://www.youtube.com/watch?v=pYxsjnC2PCs',
    cover: '/assets/images/common-refs/visa.jpeg',
    song: 'Visa',
    artist: 'M.I.A.',
    defaultBackGroundColor: '#131f2a',
    defaultTextColor: 'white',
    backgroundColor: '#fb5c23',
    titleColor: '#28130b',
    paragraphColor: '#1f0c07',
  },
  {
    genre: 'Dancehall',
    description: 'Groovy dancehall rhythm with playful rap',
    link: 'https://www.youtube.com/watch?v=GSWLzK59RU4',
    cover: '/assets/images/common-refs/yard.jpeg',
    song: 'Yard',
    artist: 'Goldlink, Haile',
    defaultBackGroundColor: '#263e54',
    defaultTextColor: '#d9f1f3',
    backgroundColor: '#049073',
    titleColor: '#0f1920',
    paragraphColor: '#082424',
  },
  {
    genre: 'Soul Pop',
    description: 'Modern soul, hip hop rhythm, gospel choir, uplifting',
    link: 'https://www.youtube.com/watch?v=BWmpyyJLhf8',
    cover: '/assets/images/common-refs/joy.jpeg',
    song: 'Joy',
    artist: 'Voli K',
    defaultBackGroundColor: '#d9f1f3',
    defaultTextColor: '#263e54',
    backgroundColor: '#aeaeae',
    titleColor: '#121212',
    paragraphColor: '#484747',
  },
  {
    genre: 'Rap Rock/Punk',
    description: 'Traditional rock with heavy bass guitar riffs, anthemic & confident vocals',
    link: 'https://www.youtube.com/watch?v=z5rRZdiu1UE',
    cover: '/assets/images/common-refs/sabotage.jpeg',
    song: 'Sabotage',
    artist: 'Beastie Boys',
    defaultBackGroundColor: '#131f2a',
    defaultTextColor: 'white',
    backgroundColor: '#fbac34',
    titleColor: '#3b2019',
    paragraphColor: '#541f21',
  },
  {
    genre: 'Pop',
    description: 'Powerful & catchy "sing-a-long" lyrics, driving drums',
    link: 'https://www.youtube.com/watch?v=CevxZvSJLk8',
    cover: '/assets/images/common-refs/roar.jpeg',
    song: 'Roar',
    artist: 'Katy Perry',
    defaultBackGroundColor: '#263e54',
    defaultTextColor: '#d9f1f3',
    backgroundColor: '#d0be5e',
    titleColor: '#192231',
    paragraphColor: '#3b300c',
  },
  {
    genre: 'Pop',
    description: 'Personal, inclusive and powerful lyrics, gen z sound',
    link: 'https://www.youtube.com/watch?v=MWASeaYuHZo',
    cover: '/assets/images/common-refs/scars-to-your-beautiful.jpeg',
    song: 'Scars to Your Beautiful',
    artist: 'Alessia Cara',
    defaultBackGroundColor: '#d9f1f3',
    defaultTextColor: '#263e54',
    backgroundColor: '#bac7c4',
    titleColor: '#151506',
    paragraphColor: '#192323',
  },
  {
    genre: 'House Pop',
    description: "90's house comeback, drag-house, empowerment lyrics",
    link: 'https://www.youtube.com/watch?v=yjki-9Pthh0',
    cover: '/assets/images/common-refs/break-my-soul.jpeg',
    song: 'Break My Soul',
    artist: 'Beyoncé',
    defaultBackGroundColor: '#131f2a',
    defaultTextColor: 'white',
    backgroundColor: '#b27d70',
    titleColor: '#05161d',
    paragraphColor: '#2a2a2f',
  },
  {
    genre: 'Indie Electronic',
    description: 'Confident & party mood, friendly sound, catchy harmony',
    link: 'https://www.youtube.com/watch?v=Z5yUDzfTexs',
    cover: '/assets/images/common-refs/wet-tennis.jpeg',
    song: 'Wet Tennis',
    artist: 'Sofi Tukker',
    defaultBackGroundColor: '#263e54',
    defaultTextColor: '#d9f1f3',
    backgroundColor: '#002f09',
    titleColor: '#f2eeee',
    paragraphColor: '#d6d8e3',
  },
  {
    genre: 'Soul Rock',
    description: 'Soulful and passionate vocals, groovy bass-line, funky horns',
    link: 'https://www.youtube.com/watch?v=sVzvRsl4rEM',
    cover: '/assets/images/common-refs/how-you-like-me-now.jpeg',
    song: 'How You Like Me Now?',
    artist: 'The Heavy',
    defaultBackGroundColor: '#d9f1f3',
    defaultTextColor: '#263e54',
    backgroundColor: '#737731',
    titleColor: '#fefeff',
    paragraphColor: '#eaebdc',
  },
  {
    genre: 'Trailer Music',
    description:
      'Top quality song for trailers, relevant lyrics, majestic progression, ups and downs',
    link: 'https://www.youtube.com/watch?v=JrVIyQeZC_8',
    cover: '/assets/images/common-refs/sequels.jpeg',
    song: 'Hurt',
    artist: '2WEI',
    defaultBackGroundColor: '#131f2a',
    defaultTextColor: 'white',
    backgroundColor: '#040404',
    titleColor: 'white',
    paragraphColor: '#c8c9c8',
  },
];

export const currentReferences = [
  {
    genre: 'M83',
    description:
      'An epic, atmospheric track characterized by soaring synths, emotive melodies, and a gradual build-up that evokes a sense of nostalgia and grandeur.',
    link: 'https://open.spotify.com/track/1s9i7W8zx7Nxx78MUIsvjV?si=d2333b8e061f4141',
    cover: '/assets/images/common-refs/current/m83.webp',
    song: 'Outro',
    artist: 'M83',
  },
  {
    genre: 'Chappell Roan',
    description:
      'This combines lush pop melodies with introspective lyrics and an eclectic production style, creating a vibrant and emotionally resonant sound.',
    link: 'https://open.spotify.com/track/7FOgcfdz9Nx5V9lCNXdBYv?si=9329831f18d34b43',
    cover: '/assets/images/common-refs/current/redwine.webp',
    song: 'Red Wine Supernova',
    artist: 'Chappell Roan',
  },
  {
    genre: 'Saweetie, Doja Cat',
    description:
      'Combines catchy hip-hop beats with playful lyrics and a vibrant, confident energy that showcases camaraderie and empowerment.',
    link: 'https://open.spotify.com/track/2etHQJxIbV0soyPhelVs9Y?si=c81c8bed094245cd',
    cover: '/assets/images/common-refs/current/bestFriends.webp',
    song: 'Best Friend',
    artist: 'Saweetie, Doja Cat',
  },
  {
    genre: 'De La Soul',
    description:
      'Take a laid-back beat with introspective lyrics that celebrate individuality and self-acceptance, embodying a blend of hip-hop and positive vibes.',
    link: 'https://open.spotify.com/track/792UwI6utk5DMQUT1KXa5E?si=2af1a6bf9d604e41',
    cover: '/assets/images/common-refs/current/de-la-soul.webp',
    song: 'Me Myself & I',
    artist: 'De La Soul',
  },
  {
    genre: 'Charlie XCX',
    description:
      'Features a blend of infectious pop hooks, nostalgic early 2000s influences, and a gritty, bass-heavy sound that captures a rebellious spirit.',
    link: 'https://open.spotify.com/track/3Y1EvIgEVw51XtgNEgpz5c?si=27fb803a89604580',
    cover: '/assets/images/common-refs/current/charli-xcx.webp',
    song: 'Von Dutch',
    artist: 'Charlie XCX',
  },
  {
    genre: 'Cookiee Kawaii',
    description:
      'Its a catchy, upbeat blend of Jersey club sounds and playful, infectious lyrics that create an energetic and feel-good atmosphere.',
    link: 'https://open.spotify.com/track/4gOgQTv9RYYFZ1uQNnlk3q?si=635360e8ee1e4c96',
    cover: '/assets/images/common-refs/current/vibe.webp',
    song: 'Vibe',
    artist: 'Cookiee Kawaii',
  },
];

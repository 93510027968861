<div (clickOutside)="openDropdown=false" class="tags-input-dropdown">
  <div class="tag-container">
    <div *ngFor="let tag of tags" class="tag">
      {{ tag }}
      <span class="tag-close" (click)="removeTag(tag)">x</span>
    </div>
    <input type="text" class="tag-input" placeholder="{{placeholder}}" 
      (keyup)="onSelectChange($event);"
      (keydown)="preventValues?.length > 0 ? preventValue($event) : ''"
      (keydown.enter)="addTagFromOption({id: 'custom', name: typedData})" 
      [(ngModel)]="typedData" (click)="openDropdown=true">
  </div>
  <div class="dropdown-container" *ngIf="openDropdown">
    <p class="options-section new-tag" *ngIf="typedData !== ''"
    (click)="addTagFromOption({id: 'custom', name: typedData})">
      Enter custom value <span class="fw600 color-dark">{{typedData}}</span></p>
      <ng-container *ngFor="let option of options">
        <p class="options-section" *ngIf="option.visible"
          (click)="addTagFromOption(option)">{{option.name}}</p>
      </ng-container>
      <p class="typing-info">Type exactly what you need</p>
  </div>
</div>

import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class IngestionsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(project): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestions`;
    return this.http.post<any>(url, project);
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestions`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestions/${id}`;
    return this.http.get<any>(url, {params: params});
  }

  getIngestionSubmission(token: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestionSubmission/${token}`;
    return this.http.get<any>(url);
  }

  update(id: string, project): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestions/${id}`;
    return this.http.put<any>(url, project);
  }

  getReviewInfo(sharedToken: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/shared/${sharedToken}`;
    return this.http.get<any>(url);
  }

  addTrackToSpace(spaceId, track): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestions/${spaceId}/track/${track.trackId}`;
    return this.http.post(url, track);
  }

  uploadToken(submissionsToken: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/ingestionSubmissionMetadata/${submissionsToken}`;
    return this.http.get<any>(url);
  }
}

import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment
  ) {
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreements`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreements/${id}`;
    return this.http.get<any>(url);
  }

  getAgreementSongs(songIds: string[]): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreementSongs`;
    return this.http.get<any>(url, {params: {_id: songIds.toString()}});
  }

  post(agreement): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreements`;
    return this.http.post<any>(url, agreement);
  }

  update(id: string, agreement): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreements/${id}`;
    return this.http.put<any>(url, agreement);
  }

  delete(id: string) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreements/${id}`;
    return this.http.delete(url);
  }

  me(): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/agreements/me`;
    return this.http.get<any>(url);
  }
}

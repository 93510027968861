import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: SostereoServicesModule
})
export class TokenStorageService {

  constructor() {
  }


  public getAccessToken(): Observable<string> {
    const token: string = <string>localStorage.getItem('accessToken');
    return of(token);
  }


  public setAccessToken(token: string): TokenStorageService {
    localStorage.setItem('satellizer_token', token);
    return this;
  }

  public clear() {
    localStorage.removeItem('satellizer_token');
    localStorage.removeItem('SOSTEREO.currentUser');
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { reverse } from 'lodash-es';
@Pipe({
  name: 'sortBy', // array | sortBy: ['name']
})
export class SortByPipe implements PipeTransform {
  transform(array: any, args: any[]): any[] {
    const field = args[0];
    const sort = args.length > 1 ? args[1] : 'desc';
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    if (sort === 'asc') {
      reverse(array);
    }
    return array;
  }
}

import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class YoutubeService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getYoutubeVideoInfoObs(videoId: string): Observable<any> {
    const url = `${this.apiHost}/core/${this.apiVersion}/references/youtube/${videoId}`;
    return this.http.get<any>(url);
  }

  getYtReferenceSTG(videoId: string, recaptcha: string, params: any = {}): Observable<any> {
    const url = `${this.environment.youtubeRef}references/${videoId}`;
    return this.http.get<any>(url, { headers: { 'g-recaptcha-response': recaptcha }, params });
  }
}

import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class PlaylistsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {

  }

  getMixtapesCount(userId: string): Observable<any> {
    const url = `${this.apiHost}/api/${this.apiVersion}/mixtape/getMixtapes/count/${userId}`;
    return this.http.get<any>(url);
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/api/${this.apiVersion}/playlists`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/api/${this.apiVersion}/playlists/${id}`;
    return this.http.get<any>(url);
  }

}

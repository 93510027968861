import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthService } from 'ngx-auth';
import { interval, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SostereoServicesModule } from '../sostereo-services.module';
import { IdentityService } from '../identity/identity.service';
import { TokenStorageService } from './token-storage.service';
import { map } from 'rxjs/operators';
import { merge, filter } from 'lodash-es';
import * as Sentry from '@sentry/browser';
import { isPlatformBrowser } from '@angular/common';

declare var dataLayer;

@Injectable({
  providedIn: SostereoServicesModule,
})
export class AuthenticationService implements AuthService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;
  public accessData = new Subject();
  public sessionSettingChange = new Subject<void>();
  public scopes: any = null;
  private publicRoutes = [
    'music-sync-licensing',
    'search',
    'discover',
    'license-your-music',
    'how-it-works',
    'faq',
    'terms-of-service',
    'privacy-policy',
  ];

  public isAccessAccount = false;
  isBrowser: boolean;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    private tokenStorageService: TokenStorageService,
    private identityService: IdentityService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.getCurrentUser()) {
      this.checkTokenExpiration();
    }
  }

  /**
   * UNUSED AUTH INTERFACE METHODS
   */
  public refreshShouldHappen(response): boolean {
    return undefined;
  }

  public verifyTokenRequest(url: string): boolean {
    return undefined;
  }

  getHeaders(token: string): { [p: string]: string | string[] } {
    return undefined;
  }

  setInterruptedUrl(url: string): void {
    return undefined;
  }

  refreshToken(): Observable<any> {
    return undefined;
  }

  /**
   * OVERWRITE AUTH INTERFACE METHODS
   */

  isAuthorized(): Observable<boolean> {
    return this.tokenStorageService.getAccessToken().pipe(map((token) => !!token));
  }

  getAccessToken(): Observable<string> {
    return this.tokenStorageService.getAccessToken();
  }

  /**
   * EXTRA AUTH METHODS
   */

  public login(user, workspace?): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/login`;
    return this.http.post(
      url,
      !workspace ? { username: user.username, password: user.password } : user,
    );
  }

  public getPendingUserByToken(token): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/auth/${token}`;
    return this.http.get(url);
  }

  public logout(isSessionExpired?): void {
    this.clearLocalStorage();
    if (
      filter(this.publicRoutes, (route) => window.location.href.indexOf(route) !== -1).length === 0
    ) {
      window.location.href = '/discover';
    } else if (!isSessionExpired) {
      window.location.href = window.location.href;
    }
    //this.setCurrentUser(null);
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
  }

  private clearLocalStorage() {
    const cookie = localStorage.getItem('SOSTEREO.cookieConsent');
    const cookieConsent = cookie ? JSON.parse(cookie) : null;
    localStorage.clear();
    if (cookieConsent) {
      localStorage.setItem('SOSTEREO.cookieConsent', 'true');
    }
  }

  checkTokenExpiration() {
    const tokenChecker = interval(300000);
    tokenChecker.subscribe(() => {
      if (this.isTokenExpired()) {
        this.logout();
      }
    });
  }

  isTokenExpired() {
    const currentUser = this.getCurrentUser();
    if (currentUser?.token) {
      const user = this.getUserFromToken(currentUser);
      const date = new Date(user.exp * 1000);
      const nextDate = new Date(date);
      nextDate.setMinutes(nextDate.getMinutes() + 60);
      const currentTime = new Date();
      currentTime.setHours(currentTime.getHours() + 5);
      return currentTime > nextDate;
    } else if (currentUser) {
      this.accessData.next(currentUser);
      this.logout();
      return false;
    }
  }

  public getUserScopes() {
    return new Promise((resolve, reject) => {
      if (this.scopes) {
        resolve(this.scopes);
      } else {
        this.identityService.getScopes().subscribe({
          next: (res) => {
            console.log('auth user data', res);
            this.scopes = res;
            return resolve(this.scopes);
          },
          error: (err) => {
            return reject(err);
          },
        });
      }
    });
  }

  setCurrentUser(userData, preventScopes?) {
    // Emitter for loggedUser object update on subscribed components (could be null)
    // If it has data, set it as local storage object (Log In)
    if (userData) {
      if (userData.token) {
        userData = this.getUserFromToken(userData);
        localStorage.setItem('SOSTEREO.currentUser', JSON.stringify(userData));
        this.tokenStorageService.setAccessToken(userData.token);

        Sentry.configureScope((scope) => {
          scope.setUser({
            username: userData.username,
            email: userData.email,
            id: userData._id,
          });
        });

        if (this.isBrowser && dataLayer) {
          dataLayer?.push({
            event: 'gaTriggerEvent',
            event_action: 'loggin user ' + userData.username,
            event_value: 'logged-in Ng7',
          });
          dataLayer?.push({
            event: 'gaTriggerEvent',
            event_action: 'loggin role ' + userData.role,
            event_value: 'logged-in Ng7',
          });
        }

        if (!preventScopes) {
          this.updateScopes(userData);
        }
      }
      this.checkTokenExpiration();
      // If it is null, remove local storage item (Log Out)
    } else {
      localStorage.removeItem('SOSTEREO.currentUser');
    }

    if (!userData?.token) {
      this.accessData.next(userData);
    }
  }

  private updateScopes(userData) {
    this.getUserScopes().then(
      (res: any) => {
        userData = { ...userData, ...res.data };
        localStorage.setItem('SOSTEREO.currentUser', JSON.stringify(userData));
        this.accessData.next(userData);
      },
      () => {
        this.accessData.next(userData);
        this.logout();
      },
    );
  }

  getUserFromToken(userData) {
    // Gets additional data from user token
    const encoded = userData.token?.split('.')[1];
    return merge(userData, encoded ? JSON.parse(atob(encoded)) : {});
  }

  getCurrentUser() {
    const getUSer = localStorage.getItem('SOSTEREO.currentUser')?.replace(/\s\s+/g, ' ') || null;
    if (getUSer) {
      localStorage.setItem('SOSTEREO.currentUser', getUSer);
    }
    return getUSer ? JSON.parse(getUSer) : null;
  }

  getUserSessionSettings() {
    return JSON.parse(localStorage.getItem('SOSTEREO.sessionSettings') || '{}');
  }

  setUserSessionSettings(settings: string) {
    localStorage.setItem('SOSTEREO.sessionSettings', settings);
    this.sessionSettingChange.next();
  }

  sendMagicTokenByEmail(email: string, reCaptcha: string, redirect?: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/magic-email`;
    return this.http.post(url, { email, 'g-recaptcha-response': reCaptcha, redirect });
  }

  postMagiclogin(magicToken: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/magic-login`;
    return this.http.post(url, { magicToken });
  }

  /**
   *
   * @param userToken :{email, amount, unit}
   * @returns : {"data":{"magicToken","magicTokenExpiration","firstName"}}
   */
  getMagicToken(userToken): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/magic-token`;
    return this.http.post(url, userToken);
  }

  isAuthenticated() {
    return this.getCurrentUser() && !this.isTokenExpired();
  }
}

import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, difference, isEmpty, reduce } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { AuthenticationService } from '../auth/authentication.service';
import { RootSimilarPlaylistTracks } from '../../models/playlistSimilarTracks.model';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class PlaylistsStereoSpacesService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  public updatePlaylistEvent = new Subject<any>();

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
  ) {}

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists`;
    return this.http.get<any>(url, { params: params });
  }

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${id}`;
    return this.http.get<any>(url, { params: params });
  }

  addTracks(playlistId, body): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}/tracks`;
    return this.http.post<any>(url, body);
  }

  post(body): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists`;
    return this.http.post(url, body);
  }

  duplicate(playlistId: string, body): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}`;
    return this.http.post(url, body);
  }

  update(playlistId: string, playlist: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}`;
    return this.http.put(url, playlist);
  }

  delete(playlistId: string) {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}`;
    return this.http.delete(url);
  }

  deleteTrack(playlistId: string, trackId: string, body?: any) {
    const options = {
      headers: {},
      body: body,
    };
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}/tracks/${trackId}`;
    return this.http.delete(url, options);
  }

  savePlaylist(playlistId: string, body): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}`;
    return this.http.post<any>(url, body);
  }

  downloadZip(playlistId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/zip',
      Accept: 'application/zip',
    });
    let param = '';
    if (
      this.route.snapshot.queryParams.hasOwnProperty('catalog') &&
      this.route.snapshot.queryParams.catalog === 'sostereo'
    ) {
      param = 'catalog=sostereo';
    }
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/${playlistId}/zip${
      isEmpty(param) ? '' : '?' + param
    }`;

    let queryParams: any = cloneDeep(this.getQueryParamsFromUrl());
    queryParams.download = 'playlist';
    queryParams.identity = this.getUserRole(this.authenticationService.getCurrentUser().scopes);

    const urlI = this.router.createUrlTree([], { queryParams: queryParams }).toString();

    this.location.go(urlI);

    return this.http.post(
      url,
      {},
      {
        headers: headers,
        responseType: 'arraybuffer',
      },
    );
  }

  validateBrands(body: Object): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/playlists/validate/brands`;
    return this.http.post(url, body);
  }

  getQueryParamsFromUrl() {
    const url = window.location.href;
    const stringParams = url.split('?')[1] || '';
    if (!stringParams) return {};
    const paramsArray = stringParams.split('&');
    return reduce(
      paramsArray,
      (result, value) => {
        const split = value.split('=');
        if (split[0]) {
          result[split[0]] = decodeURI(split[1]);
        }
        return result;
      },
      {},
    );
  }

  getRecommendedSongs(playlistId: string, params: any): Observable<RootSimilarPlaylistTracks> {
    const url = `${this.apiHost}/core/${this.apiVersion}/search/playlists/${playlistId}/suggestions`;
    return this.http.get<RootSimilarPlaylistTracks>(url, { params });
  }

  public getUserRole(scopes: any[]) {
    let userRole;
    const roles = [
      { name: 'admin', allowed: ['ListTiers'] },
      { name: 'editor', allowed: ['ListRatings', 'UpdateRating'] },
      { name: 'label', allowed: ['ListArtists', 'GetArtist'] },
      { name: 'artist', allowed: ['UpdateArtist', 'GetArtist'] },
      { name: 'brandLicenseManager', allowed: ['UpdateTransaction:LicenseManager'] },
      { name: 'brandLicenseRequester', allowed: ['UpdateTransaction:LicenseRequester'] },
      {
        name: 'brandRestrictedLicenseRequester',
        allowed: ['UpdateTransaction:RestrictedLicenseRequester'],
      },
      {
        name: 'ms',
        allowed: ['CreatePlaylist', 'CreateComment', 'CreateTransaction'],
      },
    ];

    roles.some((role) => {
      if (difference(role.allowed, scopes).length === 0) {
        userRole = role.name;
        return true;
      }
      return false;
    });
    return userRole;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreak',
})
export class LineBreakPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const text = value.replace(/\n/g, '<br/>');
      return text.replace(/\u21B5/g, '<br/>');
      // return $sce.trustAsHtml(fixed);
      // return value.replace(new RegExp('\/n', 'g'), '<br/>');
    } else {
      return '';
    }
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComponent implements OnInit {
  @Input() data: Array<any>;
  @Input() model: Array<any>;
  @Input() options: Array<any>;
  @Input() checkOptions: boolean;
  @Output() modelUpdate = new EventEmitter();
  @Output() thisType = new EventEmitter();
  @Output() resetData = new EventEmitter();

  public dropDownOpen = false;
  public mutiselectTyped = '';
  public dropdownLoading = false;
  public noResults = false;
  public loadMore = false;
  public firstQuery = false;
  private query;
  private searchTypingTimeout = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.model = this.model || [];
  }

  finishedRequest(length, loadMore) {
    this.dropdownLoading = false;
    this.noResults = !(length > 0);
    this.loadMore = loadMore;
  }

  public openOptions() {
    if (!this.firstQuery) {
      this.resetData.emit();
      this.dropdownLoading = true;
      this.firstQuery = true;
      this.thisType.emit({ query: this.query, page: 1 });
    }
  }

  public getMoreData() {
    this.thisType.emit({ query: this.query });
  }

  onSearch(event) {
    if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
      return;
    }
    clearTimeout(this.searchTypingTimeout);
    this.searchTypingTimeout = setTimeout(() => {
      this.dropdownLoading = true;
      this.loadMore = false;
      this.query = event.target.value ? `/${event.target.value}/` : null;
      this.thisType.emit({ query: this.query, page: 1 });
    }, 200);
  }

  public updateModel(item, field, check?) {
    if (check) {
      if (item.check) {
        this.model.push(item);
      } else {
        const idx = this.model.findIndex((md) => md.slug === item.slug);
        this.model.splice(idx, 1);
      }
    } else {
      const idx = this.model.findIndex((md) => md.slug === item.slug);
      if (item[field]) {
        this.model[idx][field] = item[field];
      } else {
        delete this.model[idx][field];
      }
    }
    this.modelUpdate.emit(this.model);
  }
}

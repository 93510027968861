import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RotatingTextComponent } from './components/rotating-text/rotating-text.component';
import { TrustUrlPipe } from './pipes/trust-url.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { WavesurferTimeFormatPipe } from './pipes/wavesurfer-time-format.pipe';
import { SeoService } from './services/seo.service';
import { ToastrModule } from 'ngx-toastr';
import { AllowedToDirective } from './directives/allowed-to/allowed-to.directive';
import { HideExtensionPipe } from './pipes/hide-extension.pipe';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagsSelectionComponent } from './components/tags-selection/tags-selection.component';
import { OurWorkComponent } from './components/our-work/our-work.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { LineBreakPipe } from './pipes/line-break.pipe';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertConfig, AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { FieldTableComponent } from './components/table/field-table/field-table.component';
import { ArtistsSliderComponent } from './components/artists-slider/artists-slider.component';
import { CountAnimationComponent } from './components/count-animation/count-animation.component';
import { CommonRefsComponent } from './components/common-refs/common-refs/common-refs.component';
import { LongNumbersPipe } from './pipes/long-numbers.pipe';
import { WaveSurferGenericComponent } from '../modules/shared-layout/components/search-sidebar/wave-surfer-generic/wave-surfer-generic.component';
import { TagsInputDropdownComponent } from './components/tags-input-dropdown/tags-input-dropdown.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    TooltipModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    AngularMultiSelectModule,
  ],
  declarations: [
    RotatingTextComponent,
    TrustUrlPipe,
    TruncatePipe,
    WavesurferTimeFormatPipe,
    AllowedToDirective,
    HideExtensionPipe,
    SortByPipe,
    LongNumbersPipe,
    TagsSelectionComponent,
    OurWorkComponent,
    LineBreakPipe,
    MultiselectComponent,
    FieldTableComponent,
    ArtistsSliderComponent,
    CountAnimationComponent,
    CommonRefsComponent,
    WaveSurferGenericComponent,
    TagsInputDropdownComponent,
  ],
  exports: [
    RotatingTextComponent,
    TrustUrlPipe,
    TruncatePipe,
    WavesurferTimeFormatPipe,
    BsDropdownModule,
    AllowedToDirective,
    HideExtensionPipe,
    SortByPipe,
    TagsSelectionComponent,
    OurWorkComponent,
    LineBreakPipe,
    LongNumbersPipe,
    MultiselectComponent,
    ArtistsSliderComponent,
    CountAnimationComponent,
    CommonRefsComponent,
    WaveSurferGenericComponent,
    TagsInputDropdownComponent,
    FieldTableComponent,
  ],
  providers: [TooltipConfig, AlertConfig, SeoService, WavesurferTimeFormatPipe, DecimalPipe],
})
export class SharedModule {}

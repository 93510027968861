<p *ngIf="col.dataType === 'link'">
  <a [routerLink]="[col.urlLink, data[col.urlField]]">
    {{ col.linkTitle || data[col.field] }}
  </a>
</p>

<a *ngIf="col.icon" class="icon-container">
  <span *ngIf="col.iconText" (click)="getRowAction(tabID, col, data, i)">
    {{ col.iconText }}
  </span>
  <i
    class="{{ col.icon }} table-icon"
    disabled="true"
    *ngIf="
      !col.hasOwnProperty('displayIf') ||
      (col.hasOwnProperty('displayIf') &&
        (data[col.field] === col.displayIf ||
          (col.displayIf && col.displayIf.indexOf(data[col.field]) !== -1)))
    "
    (click)="getRowAction(tabID, col, data, i)"
    >{{ col.iconMaterialText || '' }}</i
  >
</a>

<div [ngClass]="{ 'inline-block': col.tooltipInfo }">
  <p *ngIf="col.dataType === 'text' && col.oppBody" class="pl0">{{ data[col.field] }}</p>
</div>

<div *ngIf="col.dataType === 'object'" [ngClass]="{ 'inline-block': col.tooltipInfo }">
  <div
    *ngFor="let property of col.objectProperties"
    [ngClass]="{ 'inline-block': col.tooltipInfo }"
    class="mt5 mb5"
  >
    <p *ngIf="property.dataType === 'text'">
      {{
        data[col.field] &&
        (!property.hasOwnProperty('displayIf') ||
          (data[col.field]['id'] && property.displayIf === data[col.field]['id']))
          ? data[col.field][property.field]
          : ''
      }}
    </p>
    <a (click)="getRowAction(tabID, col, data, i)" *ngIf="property.dataType === 'action-link'">{{
      data[col.field] &&
      (!property.hasOwnProperty('displayIf') ||
        (data[col.field]['id'] && property.displayIf === data[col.field]['id']))
        ? data[col.field][property.field]
        : ''
    }}</a>
    <div *ngIf="property.dataType === 'object'">
      <div *ngFor="let subProperty of property.objectProperties">
        <div *ngIf="subProperty.dataType === 'icon'">
          <a class="icon-container">
            <i
              class="{{ subProperty.icon }} table-icon"
              disabled="true"
              (click)="getRowAction(tabID, subProperty, data, i)"
            >
            </i>
          </a>
          <p>{{ subProperty.label }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

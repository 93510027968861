import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OpportunityModel } from '../../models/opportunity.model';

@Injectable({
  providedIn: 'root'
})
export class InquiriesService {

  private apiHost = this.environment.apiHost;
  private corePath = this.environment.corePath;
  private apiVersion = this.environment.apiVersion;
  private apiPath = this.environment.apiPath;

  constructor(private http: HttpClient, @Inject('environment') private environment) { }

  post(inquiry): Observable<any> {
    const url = `${this.apiHost}/notifications/${this.apiVersion}/inquiries`;
    return this.http.post<any>(url, inquiry);
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/notifications/${this.apiVersion}/inquiries`;
    return this.http.get<any>(url, { params: params });
  }

  getById(id: string): Observable<OpportunityModel> {
    const url = `${this.apiHost}/notifications/${this.apiVersion}/inquiries/${id}`;
    return this.http.get<any>(url);
  }

  update(id: string, inquiry): Observable<any> {
    const url = `${this.apiHost}/notifications/${this.apiVersion}/inquiries/${id}`;
    return this.http.put<any>(url, inquiry);
  }
}

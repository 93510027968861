import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation } from '../../animations/in-out.animation';

@Component({
  selector: 'app-tags-selection',
  templateUrl: './tags-selection.component.html',
  styleUrls: ['./tags-selection.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
})
export class TagsSelectionComponent implements OnInit {
  @Input() tags;
  @Output() selectedTag = new EventEmitter();
  @Input() tagIndexLimit = 30;
  @Output() moreTagsRequested = new EventEmitter();
  @Output() lessTagsRequested = new EventEmitter();

  ngOnInit() {}

  public selectTag(tag) {
    this.selectedTag.emit(tag);
  }

  public requestMoreTags() {
    this.moreTagsRequested.emit();
  }

  public requestLessTags() {
    this.lessTagsRequested.emit();
  }
}

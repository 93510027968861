import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class TagsRsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/tags-rs/${this.apiVersion}/search`;
    return this.http.get<any>(url, {params: params});
  }
}

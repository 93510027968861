import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  post(subscription): Observable<any> {
    const url = `${this.apiHost}/notifications/${this.apiVersion}/subscriptions`;
    return this.http.post<any>(url, subscription);
  }

  postAll(subscription): Observable<any> {
    const url = `${this.apiHost}/notifications/${this.apiVersion}/subscriptions/all`;
    return this.http.post<any>(url, subscription);
  }
}

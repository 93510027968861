import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PlayerWidgetTrackModel } from '../../shared/models/player-widget-track.model';
import { AuthenticationService } from 'sostereo-services';

@Injectable({
  providedIn: 'root',
})
export class PlayerWidgetService {
  public track: PlayerWidgetTrackModel;
  public queue: PlayerWidgetTrackModel[];
  public showPlayer: boolean;
  public playerActive: boolean;
  public autoPlay: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public play = new Subject<any>();
  public queueUpdated = new Subject<void>();
  public pause = new Subject<void>();
  public isPlaying = new Subject<any>();
  public isPaused = new Subject<any>();
  public newTrack = new Subject<void>();
  public updateComments = new Subject<any>();
  public listComments = new Subject<any>();
  public updatedPlaylistId = new Subject<any>();
  public trackAddedToPlaylist = new Subject<any>();
  public updatePlayer = new Subject<void>();
  public isFinished = new Subject<void>();
  public showSelectSegment: boolean = false;

  constructor(private authenticationService: AuthenticationService) {
    this.showPlayer = false;
    this.playerActive = false;
  }

  // This method is called by the parent component, it shows the player, sets the new track and notifies the player widget
  public playSong(track: PlayerWidgetTrackModel, chooseSegment?: boolean) {
    this.showPlayer = true;
    this.playerActive = true;
    this.showSelectSegment = chooseSegment || false;
    this.play.next(track);
  }

  // This method is called by the parent component, sets the queue of songs that will play and notifies the player widget component
  public setQueue(queue: Array<PlayerWidgetTrackModel>) {
    this.queue = queue;
    this.queueUpdated.next();
  }

  // This method is called by the parent component, in order to pause the current track
  public pauseSong() {
    this.playerActive = false;
    this.pause.next();
  }

  // This method is called by the player widget ,it notifies the subscribed components that the play button was clicked
  public songPlaying(track: PlayerWidgetTrackModel) {
    this.playerActive = true;
    this.isPlaying.next(track);
  }

  // This method is called by the player widget ,it notifies the subscribed components that the pause button was clicked
  public songPaused(track: PlayerWidgetTrackModel) {
    this.playerActive = false;
    this.isPaused.next(track);
  }

  // This method is called by the player widget ,it notifies the subscribed components and itself that the current song has finished
  public songFinished() {
    this.autoPlay.next(true);
    this.isFinished.next();
  }

  // This method is called by the player widget ,it notifies the subscribed components about the new track in the widget
  public setNewTrack(track: PlayerWidgetTrackModel) {
    this.track = track;
    this.showPlayer = true;
    this.playerActive = true;
    this.newTrack.next();
  }

  // This method is called to get the current track that is currently on
  public getCurrentTrack() {
    return this.track;
  }

  // This method is called by the parent component, it pauses the current song and hides the widget
  public hidePlayer() {
    this.pauseSong();
    this.showPlayer = false;
  }

  // This method is called by the player widget, it notifies the subscribed components about the
  // "AddToPlaylist" actions response, it can be 'new' for a new playlist or 'existing' for an existing one.
  public trackAddedToPlaylistEvent(type) {
    // event can be 'new' or 'existing'
    this.trackAddedToPlaylist.next(type);
  }

  public emitUpdatePlayer() {
    this.updatePlayer.next();
  }

  // This method is called to update comments right after song gets played for the first time
  public setComments(comments) {
    this.listComments.next(comments);
  }

  // This is called when a PL is saved and the track needs to update the PLId
  public updateTrackPlaylistId(playlistId) {
    this.updatedPlaylistId.next(playlistId);
  }

  public updateCommentsEvent(commentItems) {
    this.updateComments.next(commentItems);
  }

  public setAutoPlay() {
    const sessionSettings = this.authenticationService.getUserSessionSettings();
    this.autoPlay.next(!!!sessionSettings?.playerWidgetConfig?.autopause);
  }
}

import { animate, animation, style } from '@angular/animations';

export const inOutAnimation = animation([
  style({ opacity: 0 }),
  animate('0.2s ease-out', style({ opacity: 1 })),
]);

export const outInAnimation = animation([
  style({ opacity: 1 }),
  animate('0.2s ease-in', style({ opacity: 0 })),
]);

export const inOutAnimationSlow = animation([
  style({ opacity: 0 }),
  animate('1s ease-out', style({ opacity: 1 })),
]);

export const outInAnimationSlow = animation([
  style({ opacity: 1 }),
  animate('1s ease-in', style({ opacity: 0 })),
]);

<script src="https://www.youtube.com/player_api"></script>
<div class="row ml0 mr0" id="our-work" style="background-color: white">
  <div class="col-md-12 top-margin-lg" style="background-color: white">
    <h1 class="color-dark centered-text mt0 mb10 mb30">We Put Epic Music To Work for Your Brand</h1>
    <div
      class="video-container"
      style="background-image: url('./assets/images/customers-logos/clients.webp')"
    >
      <div class="video-wrapper" (mouseleave)="pauseLandingVideo()">
        <div class="clients-video">
          <iframe
            data-hj-allow-iframe=""
            frameborder="0"
            allowfullscreen="1"
            style="width: 100%; height: 100%"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="Vimeo video player"
            src="https://player.vimeo.com/video/963330931?showinfo=0"
            #iframe
          ></iframe>
          <div class="collapse-email">
            <div class="mt10">
              <button
                class="pt5 pb5 mb15 btn primary-light-btn"
                style="width: 100%"
                [routerLink]="['/our-work']"
                (click)="
                  trackEvent('Our Work CallToAction', 'click', {
                    event_action: 'Our work landing clicked',
                    event_type: 'Button Click',
                    event_value: 'See more of our work'
                  })
                "
              >
                See more of our work
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
              <div class="pt5 pb5 mb10 primary">
                <h5 class="mb0 centered-text fw500">Need help with an upcoming project?</h5>
                <h5 class="mt5 centered-text fw500">
                  Submit your email and our creative team will reach out to help!
                </h5>
              </div>
              <div class="email-container">
                <input
                  class="input-lg required email mb10"
                  type="email"
                  placeholder="Email"
                  value="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  [formControl]="emailControl"
                />
                <button
                  class="btn-hover primary-light-btn lg-btn btn"
                  type="submit"
                  style="margin-top: -4px"
                  [disabled]="loadingRequest || emailControl.invalid"
                  name="subscribe"
                  value="Submit"
                  (click)="submit()"
                >
                  Submit
                  <i class="spin medium" *ngIf="loadingRequest"></i>
                </button>
              </div>
            </div>
            <p
              *ngIf="responseData?.message"
              [ngClass]="responseData.error ? 'error-color' : 'secondary-color'"
            >
              {{ responseData.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="isolate-email mb20">
      <button
        class="pt5 pb5 mb15 btn primary-light-btn go-to-button"
        [routerLink]="['/our-work']"
        (click)="
          trackEvent('Our Work CallToAction', 'click', {
            event_action: 'Our work landing clicked',
            event_type: 'Button Click',
            event_value: 'See more of our work'
          })
        "
      >
        See more of our work
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </button>
      <div class="mt20" [ngClass]="{ mb60: !responseData?.message }">
        <div class="color-dark">
          <p class="mb0 fw500">Need help with an upcoming project?</p>
          <p class="fw500">Submit your email and our creative team will reach out to help!</p>
        </div>
        <div class="form-group">
          <input
            class="form-control required email mb10 email-container"
            type="email"
            placeholder="Email"
            value="email"
            name="EMAIL"
            id="mce-EMAIL"
            [formControl]="emailControl"
          />
          <button
            class="btn primary-light-btn email-container mt20 mb20"
            type="submit"
            style="margin-top: 10px"
            [disabled]="loadingRequest || emailControl.invalid"
            name="subscribe"
            value="Submit"
            (click)="submit()"
          >
            Submit
            <i class="spin medium" *ngIf="loadingRequest"></i>
          </button>
        </div>
      </div>
      <p *ngIf="responseData?.message" [ngStyle]="{ color: responseData.color }">
        {{ responseData.message }}
      </p>
    </div>
  </div>
</div>

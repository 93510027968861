import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AuthenticationService, IdentityService, NotificationService } from 'sostereo-services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { cloneDeep, difference } from 'lodash-es';
import { Location, isPlatformBrowser } from '@angular/common';

declare const window, dataLayer;

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  currentUser: any;
  scopes: any;
  isBrowser: boolean;
  signup = false;

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private router: Router,
    private http: HttpClient,
    private identityService: IdentityService,
    private route: ActivatedRoute,
    private location: Location,
    @Inject('environment') private environment,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.currentUser = this.authenticationService.getCurrentUser();
    this.authenticationService.accessData.subscribe((user) => {
      this.currentUser = user;
    });
  }

  upperCaseFirstLetter(word) {
    return word.replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
      return letter.toUpperCase();
    });
  }

  track(eventName: string, eventParams, gaParams?) {
    if (!eventParams) {
      eventParams = {};
    }
    eventParams.origin = this.router.url;
    eventParams.version =
      'v' +
      this.environment.appVersion +
      (this.environment.buildNumber ? '-' + this.environment.buildNumber : '');

    // To know is running locally
    if (!this.environment.production) {
      eventParams.env = this.environment.env;
    }
    if (this.currentUser && this.currentUser?.email && window.mixpanel) {
      //set_once() method will only add user properties if they doesn't exist yet
      window.mixpanel.people.set_once({
        $first_name: this.currentUser.firstName,
        $last_name: this.currentUser.lastName,
        $email: this.currentUser.email,
      });
      eventParams.email = this.currentUser.email;
    }

    for (const key in eventParams) {
      if (key === 'origin') {
        if (eventParams[key] === '/') {
          eventParams.origin = 'Landing';
        }
      }
    }
    console.log('TRACK ', eventName, eventParams);
    // Mixpanel tracking
    if (window.mixpanel) {
      window.mixpanel.track(eventName, eventParams);
      if (eventParams.hasOwnProperty('targetId')) {
        this.setCollection(eventName, eventParams);
      }
    }
    //Google Analytics 4 tracking
    if (gaParams && this.isBrowser) {
      dataLayer?.push({
        event: 'gaTriggerEvent',
        event_user_email: this.currentUser?.email || undefined,
        event_type: undefined,
        event_action: undefined,
        event_value: undefined,
        element_type: undefined,
        element_value: undefined,
        element_id: undefined,
        element_item_type: undefined,
        element_item_value: undefined,
        element_item_id: undefined,
        ...gaParams,
      });
    }
  }

  setIdentity(user, eventType, setPeople?) {
    this.currentUser = this.authenticationService.getCurrentUser();
    console.log('setIdentity ng7 >>> ', user, setPeople);
    if (window.mixpanel) {
      console.log('Mixpanel True NG7 >>> ');
      if (eventType === 'signup') {
        window.mixpanel.alias(user._id || user.id);
        this.signup = true;
      } else {
        if (!this.signup) {
          window.mixpanel.alias(user._id || user.id);
        }
        window.mixpanel.identify(user._id || user.id);
      }
      if (setPeople) {
        window.mixpanel.people.set({
          $first_name: user.firstName,
          $last_name: user.lastName,
          $email: user.email,
          $created: user.createdAt,
        });
      }
    }
    if (this.isBrowser && dataLayer) {
      dataLayer?.push({
        event: 'userIdTrigger',
        event_action: 'Set user id',
        user_id: user._id.toString() || user.id.toString(),
      });
    }

    if (this.currentUser) {
      this.setGA4Role();
    }
  }

  public getUserRole(scopes: any[]) {
    let userRole;
    const roles = [
      { name: 'admin', allowed: ['ListTiers'] },
      { name: 'editor', allowed: ['ListRatings', 'UpdateRating'] },
      { name: 'label', allowed: ['ListArtists', 'GetArtist'] },
      { name: 'artist', allowed: ['UpdateArtist', 'GetArtist'] },
      { name: 'brandLicenseManager', allowed: ['UpdateTransaction:LicenseManager'] },
      { name: 'brandLicenseRequester', allowed: ['UpdateTransaction:LicenseRequester'] },
      {
        name: 'brandRestrictedLicenseRequester',
        allowed: ['UpdateTransaction:RestrictedLicenseRequester'],
      },
      {
        name: 'ms',
        allowed: ['CreatePlaylist', 'CreateComment', 'CreateTransaction'],
      },
    ];

    roles.some((role) => {
      if (difference(role.allowed, scopes).length === 0) {
        userRole = role.name;
        return true;
      }
      return false;
    });
    return userRole;
  }

  trackingAds(event) {
    if (this.environment.name === 'sostereo') {
      if (event.gTag && window.gtag) {
        window.gtag('event', 'conversion', {
          send_to: `${this.environment.googleAdsId}/${event.gTag.id}`,
        });
      }
      if (event.linkedIn && window.lintrk) {
        window.lintrk('track', { conversion_id: event.linkedIn.id });
      }
    }
  }

  public setGA4Role() {
    this.identityService.getScopes().subscribe({
      next: (res) => {
        this.scopes = res.data?.scopes || [];
        let userRole = this.getUserRole(this.scopes);
        if (userRole) {
          if (this.isBrowser && dataLayer) {
            dataLayer?.push({
              event: 'userRoleTrigger',
              event_action: 'Set user role',
              user_role: userRole,
            });
          }

          let queryParams: any = cloneDeep(this.commonService.getQueryParamsFromUrl());
          queryParams.identity = userRole;
          this.trackViewPage(queryParams);
        }
      },
      error: (httpErr) => {
        console.log('httpErr identities/me', httpErr);
      },
    });
  }

  public setCollection(eventName, eventParams) {
    this.currentUser = this.authenticationService.getCurrentUser();
    const subdomain = this.commonService.getSubdomain();
    let tenantId = 'sostereo';
    if (subdomain) {
      this.commonService.getCompany(subdomain, (company) => {
        if (company) {
          tenantId = company.tenantId.replace('Company-', '');
        }
      });
    }
    const data: any = {
      targetId: eventParams.targetId,
      event: eventName,
      tenantId: tenantId,
      objectData: eventParams,
    };
    if (this.currentUser) {
      data.userEmail = this.currentUser.email;
      data.uid = this.currentUser.uid;
    }
    if (eventParams.statusCode) {
      data.statusCode = eventParams.statusCode;
    }
    console.log('notificationService.collect data >>> ', data);
    this.notificationService.collect(data).subscribe(
      (res) => {
        console.log('notificationService.collect Res >>> ', res);
      },
      (err) => {
        window.mixpanel.track('Collect data notificationService error', { data: data, error: err });
        console.log('notificationService.collect Err >>>  ', err);
      },
    );
  }

  trackViewPage(queryParams) {
    if (queryParams?.download === 'song' || queryParams?.download === 'playlist') {
      this.trackingAds(
        queryParams?.download === 'song'
          ? {
              gTag: { id: '0Mv1CLH1o-oYEO75s58p' },
              linkedIn: { id: '14017618' },
            }
          : {
              gTag: { id: '8jSpCM7uoeoYEO75s58p' },
              linkedIn: { id: '14017610' },
            },
      );
    }
    const urlI = this.router.createUrlTree([], { queryParams }).toString();
    this.location.go(urlI);
    this.track(
      'Page View',
      { url: urlI },
      { event_action: 'Page View', event_type: 'URL param change', event_value: urlI },
    );
  }

  trackDownloadView() {
    let queryParams: any = {};
    queryParams.download = 'playlist';
    queryParams.identity = this.getUserRole(this.authenticationService.getCurrentUser().scopes);
    this.trackViewPage(queryParams);
  }

  resetMixpanelSession() {
    window.mixpanel.reset();
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation } from '../../animations/in-out.animation';
import { SlidersService } from 'sostereo-services';
import { finalize } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../services/common.service';
import { TrackingService } from '../../services/tracking.service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-artists-slider',
  templateUrl: './artists-slider.component.html',
  styleUrls: ['./artists-slider.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
})
export class ArtistsSliderComponent implements OnInit, OnChanges {
  @Input() isSostereoTenant;
  @Input() company;
  @Input() briefForm;
  @Output() loadingSlider = new EventEmitter();

  public index = 0;
  private sliderState = {
    'main.views.player-playlist': { url: '/music-sync-licensing/playlist', slugName: 'slug' },
    'main.views.player-artist': { url: '/music-sync-licensing/artist', slugName: 'artistSlug' },
  };

  public item: any = null;
  public subdomain = '';
  isBrowser: boolean;

  constructor(
    private sliderService: SlidersService,
    private deviceDetectorService: DeviceDetectorService,
    private commonService: CommonService,
    private trackingService: TrackingService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.loadingSlider.emit(false);
    if (this.briefForm) {
      this.getSliderImage();
    }
  }

  ngOnChanges() {
    if (this.company) {
      this.loadingSlider.emit(true);
      this.subdomain = this.commonService.getSubdomain();
      if (this.subdomain) {
        if (
          (this.company.tenantId?.toLowerCase().includes('sostereo') &&
            !this.company?.theme?.customLoginBg) ||
          (this.company?.theme?.customLoginBg && this.company?.backgroundImages?.length === 0)
        ) {
          this.getSliderImage();
        } else {
          this.loadingSlider.emit(false);
          if (this.company?.backgroundImages?.length > 0) {
            this.item = { background: { src: this.company?.backgroundImages[0] } };
          }
          this.isSostereoTenant = false;
        }
      } else {
        this.getSliderImage();
      }
    }
  }

  changeImage(context, images) {
    context.item = images[context.index];
    if (context.index >= images.length - 1) {
      context.index = 0;
    } else {
      context.index++;
    }
    context.changeDetectorRef.detectChanges();
  }

  getSliderImage() {
    //this.loadingSlider.emit(true);
    const slidersQuery = { segments: 'landing', limit: 1 };
    this.sliderService
      .getSliders(slidersQuery)
      .pipe(finalize(() => this.loadingSlider.emit(false)))
      .subscribe((sliders) => {
        this.index = Math.floor(Math.random() * sliders.data.items[0].items.length || 0);
        this.changeImage(this, sliders.data.items[0].items);
        if (
          !this.deviceDetectorService.isMobile() ||
          (this.deviceDetectorService.isMobile() && this.briefForm)
        ) {
          if (this.isBrowser) {
            setInterval(this.changeImage, 10000, this, sliders.data.items[0].items);
          }
        }
      });
  }

  public numberToLetterFormat(listeners: number) {
    return listeners ? this.commonService.addLetterToLargeNumber(listeners) : '';
  }

  public goToSlide(slide) {
    this.router.navigateByUrl(
      `/${this.sliderState[slide.redirectState].url}/${
        slide.stateParams[this.sliderState[slide.redirectState].slugName]
      }`,
    );
    const trackData = {
      itemName: slide.name,
      itemId: slide.itemId ? slide.itemId : '',
    };
    this.trackingService.track('Clicked Slider Item Login', trackData, {
      event_action: 'Slider item clicked (login)',
      event_type: 'Slider Click',
      element_type: 'Slider',
      element_value: slide?.name,
      element_id: slide?.itemId || slide?.itemId,
    });
  }

  public goTo(path: string) {
    this.router.navigateByUrl(path);
  }
}

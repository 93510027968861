import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class TagsService {
  private apiHost = this.environment.apiHost;
  private corePath = this.environment.corePath;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tags`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tags/${id}`;
    return this.http.get<any>(url);
  }

  post(tag): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tags`;
    return this.http.post<any>(url, tag);
  }

  update(id: string, tag): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tags/${id}`;
    return this.http.put<any>(url, tag);
  }

  delete(id: string) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tags/${id}`;
    return this.http.delete(url);
  }
}

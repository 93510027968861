import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SostereoServicesModule } from '../sostereo-services.module';

@Injectable({
  providedIn: SostereoServicesModule
})
export class TransfersService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/jobs`;
    return this.http.get<any>(url, { params: params });
  }

  get(token: string): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/jobs/${token}`;
    return this.http.get<any>(url);
  }

  getSignedUrl(data: object): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/jobs/signedUrl`;
    return this.http.post<any>(url, data);
  }

  // TODO: Check this service if it's a duplicate to the 'get' one
  getByJobTokenOrId(jobToken: string) {
    const url = `${this.apiHost}/storage/${this.apiVersion}/jobs/${jobToken}`;
    return this.http.get<any>(url);
  }

  createTransfer(job: any) {
    const url = `${this.apiHost}/storage/${this.apiVersion}/jobs/`;
    return this.http.post<any>(url, job);
  }

  deleteTransfer(jobId: string) {
    const url = `${this.apiHost}/storage/${this.apiVersion}/jobs/${jobId}`;
    return this.http.delete<any>(url);
  }
}

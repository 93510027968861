import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class LocationsService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private core = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  // https://api-qa.sostereo.com/core/v1/locations/cities?country=US&city=/Angeles/
  getCities(location): Observable<any> {
    const url = `${this.apiHost}/${this.core}/${this.apiVersion}/locations/cities`;
    return this.http.get<any>(url, { params: location });
  }
}

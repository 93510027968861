import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';
import {TierModel} from '../../models/tier.model';

@Injectable({
  providedIn: SostereoServicesModule
})
export class GroupsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/groups`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/groups/${id}`;
    return this.http.get<any>(url, {params: params});
  }

  post(policy): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/groups`;
    return this.http.post<any>(url, policy);
  }

  update(id: string, company): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/groups/${id}`;
    return this.http.put<any>(url, company);
  }

  delete(id: string) {
    const url = `${this.apiHost}/iam/${this.apiVersion}/groups/${id}`;
    return this.http.delete(url);
  }
}

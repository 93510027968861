import {Deserializable} from './deserializable';

export class TierModel implements Deserializable {
  _id?: string;
  name: string;
  status?: string;
  description?: string;
  priceMatrix?: any[];
  scenarios?: any[];
  customTermsDisabled?: boolean;
  standardTermsDisabled?: boolean;
  offlineContractDisabled?: boolean;
  totalSongs?: number;
  publicAccess?: boolean;
  minBudget: {
    internal: number;
    film: {
      scene: number;
      trailer: number;
      credits: number;
    };
    tv: {
      credits: number;
      trailer: number;
      theme: number;
      scene: number;
    };
    ads: {
      socialMedia: number;
      radio: number;
      onlineVideos: number;
      tvBroadcast: number;
      allMedia: number;
    };
  };

  deserialize(input: any): this {
    input.publicAccess = (input.publicAccess === 'true' || input.publicAccess === true);
    return Object.assign(this, input);
  }
}


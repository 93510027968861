import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: SostereoServicesModule
})
export class EmbedsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private subject = new Subject<any>();
  public configCubeChange = new Subject<any>();


  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.setConfig();
  }

  getEmbeds(): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/embeds`;
    return this.http.get<any>(url);
  }

  getJWT(): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/embeds/mongo/jwtToken`;
    return this.http.get<any>(url);
  }

  setConfig() {
    this.subject.pipe(debounceTime(300)).subscribe(res => {
      const url = `${this.apiHost}/organization/${this.apiVersion}/embeds/cube/jwtToken`;
      this.http.get<any>(url).pipe(map((x) => ({
        token: x.data,
        options: this.environment.cubeConfig.options
      })), retry(2)
      ).subscribe(config => {
        this.configCubeChange.next(config);
      }, error => {
        this.configCubeChange.error(error);
      })
    });
  }

  getCubeConfig() {
    this.subject.next(true);
  }
}

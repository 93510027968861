import { Deserializable } from './deserializable';

export class Album implements Deserializable {

  _id: string;
  name: string;
  albumType: string;
  artists: any[];
  status: string;
  discNumber: number;
  popularity: string;
  releaseDate: string;
  releaseDatePrecision: string;
  totalTracks: number;
  spotifyId: string;
  images: any[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'sostereo-services';

/**
 * Example in template:
 * <button [appAllowedTo]="'UpdateSpace'" [disabledElement]="true" >Edit</button>
 * The disabledElement is not required
 */

@Directive({
  selector: '[appAllowedTo]',
})
export class AllowedToDirective implements OnInit {
  @Input() appAllowedTo: string;
  @Input() disabledElement: boolean;
  loggedUser: any;
  constructor(private elem: ElementRef, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.loggedUser = this.authenticationService.getCurrentUser();
    if (this.loggedUser && this.loggedUser.scopes) {
      const splitScope = this.appAllowedTo.split(/(?=[A-Z])/);
      const scopeAll = splitScope[0] + (!!splitScope[1] ? splitScope[1] : '') + 'All';
      const hasPermission =
        this.loggedUser.scopes.includes(
          this.appAllowedTo.includes('!') ? this.appAllowedTo.replace('!', '') : this.appAllowedTo,
        ) || this.loggedUser.scopes.includes(scopeAll);
      if (this.appAllowedTo.includes('!')) {
        if (hasPermission) {
          this.elem.nativeElement.remove();
        } else if (this.loggedUser.scopes.indexOf('UpdateSearchSettings') !== -1) {
          this.elem.nativeElement.remove();
        }
      } else if (!hasPermission && this.loggedUser.scopes.indexOf('UpdateSearchSettings') === -1) {
        if (this.hasOwnProperty('disabledElement') && this.disabledElement === true) {
          this.elem.nativeElement.disabled = true;
          this.elem.nativeElement.style.cursor = 'not-allowed';
          this.elem.nativeElement.style.opacity = '0.5';
        } else {
          this.elem.nativeElement.remove();
        }
      }
    } else {
      this.elem.nativeElement.remove();
    }
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: SostereoServicesModule
})
export class ContactUsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  sendEmail(contactUsForm): Observable<any> {

    const url = `${this.apiHost}/api/${this.apiVersion}/user/sendContactMail`;
    return this.http.post<any>(url, contactUsForm);
  }

  customMusicRequest(customServicesForm): Observable<any> {
    const url = `${this.apiHost}/api/${this.apiVersion}/user/customMusicRequest`;
    return this.http.post<any>(url, customServicesForm);
  }
}

import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WaveformService {
  private waveService = this.environment.waveService;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

//  Previous Request `https://s3-us-west-2.amazonaws.com/${this.waveService}/${waveFileName}.json.gz`,
  getSongWaveform(waveFileName: string, waveformUrl?: boolean): Observable<any> {
    const headers = new HttpHeaders({});
    const req = new HttpRequest(
      'GET',
      waveformUrl ? waveFileName : `https://${this.waveService}/${waveFileName}.json.gz`,
      {
        headers: headers,
        withCredentials: false
      }
    );
    return this.http.request(req);
  }

  get(url: string, waveformUrl?: boolean): Observable<any> {
    const headers = new HttpHeaders({});
    const req = new HttpRequest(
      'GET',
      (waveformUrl ? url : (url.indexOf('waves') != -1 ? url :
        url.replace('cdn-sostereo.sostereo.com', this.waveService)
         .replace('cdn-sostereo-production.sostereo.com', this.waveService)
         .replace('cdn-sp-production.stereospaces.com', this.waveService)
         .replace('cdn-sp-stg.stereospaces.com', this.waveService)
         .replace('-music-', '-waves-')
         .replace('@streaming', '')
         .replace(/.mp3|(.wav$)|.aif|.aiff/i, '.json.gz'))) + '?ngsw-bypass=true',
      {
        headers: headers,
        withCredentials: false
      }
    );
    return this.http.request(req);
  }

  getSongBlob(urlFile: string): Observable<any> {
    return this.http.get(urlFile, { responseType: 'blob' });
  }
}

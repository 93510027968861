import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class LikeService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(like): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/likes`;
    return this.http.post<any>(url, like);
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';
import { Artist } from '../../models/artist.model';
import { Observable } from 'rxjs';
import { SostereoServicesModule } from '../sostereo-services.module';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class ArtistService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getArtistByUserId(userId: string): Observable<any> {
    const url = `${this.apiHost}/api/${this.apiVersion}/artist/findArtistByUserId/${userId}`;
    return this.http.get<any>(url);
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists`;
    return this.http.get(url, { params: params }).pipe(
      map((res) => {
        return {
          items: res['data'].items.map((artist: Artist) => new Artist().deserialize(artist)),
          paging: res['data'].paging,
        };
      }),
    );
  }

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/${id}`;
    return this.http.get<any>(url);
  }

  me(): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/me`;
    return this.http.get<any>(url);
  }

  update(id: string, artist: Artist): Observable<Artist> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/${id}`;
    return this.http.put<Artist>(url, artist);
  }

  bulk(artistArray: any): Observable<Artist> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists`;
    return this.http.put<Artist>(url, artistArray);
  }

  save(artist): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists`;
    return this.http.post<any>(url, artist);
  }

  uploadImage(id: string, file: File, doThumb?: boolean): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (doThumb) {
      formData.append('doThumb', doThumb.toString());
    }

    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/${
      id ? id + '/' : ''
    }image`;
    const req = new HttpRequest('PUT', url, formData, {
      reportProgress: true,
    });
    return this.http.request(req);
  }

  invite(id: string, body): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/${id}/invite`;
    return this.http.put<Artist>(url, body);
  }

  validateBrands(body: Object): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/validate/brands`;
    return this.http.post(url, body);
  }

  getStats() {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/artists/stats`;
    return this.http.get<any>(url);
  }

  getSimilarArtists(slug: string, params?): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/search/artists/${slug}/suggestions`;
    return this.http.get<any>(url, { params: params });
  }

  getArtistsList(params?) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/search/artists/`;
    return this.http.get<any>(url, { params: params });
  }
}

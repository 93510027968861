import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { TrackingService } from '../../services/tracking.service';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'sostereo-services';
import { ToastrService } from 'ngx-toastr';
import Player from '@vimeo/player';

@Component({
  selector: 'app-our-work',
  templateUrl: './our-work.component.html',
  styleUrls: ['./our-work.component.scss'],
})
export class OurWorkComponent implements AfterViewInit {
  @ViewChild('iframe', { static: true }) iframe: ElementRef;
  private player: any;
  public emailControl = new FormControl('', [Validators.required, Validators.email]);
  public loadingRequest = false;
  public responseData;

  constructor(
    private trackingService: TrackingService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
  ) {}

  ngAfterViewInit() {
    this.player = new Player(this.iframe.nativeElement);
    this.player.on('play', () => {
      this.trackingService.track(
        'Play Landing Video',
        {
          action: 'Play',
        },
        {
          event_action: 'Landing video played',
          event_type: 'Video Play',
        },
      );
    });
    this.player.on('pause', () => {
      this.trackingService.track(
        'Play Landing Video',
        {
          action: 'Pause',
        },
        {
          event_action: 'Landing video paused',
          event_type: 'Video Pause',
        },
      );
    });
  }

  pauseLandingVideo() {
    this.player.pause();
  }

  submit() {
    this.loadingRequest = true;
    if (this.emailControl.valid) {
      const email = this.emailControl.value;
      const notification = {
        email: email,
        subject: 'Thanks for reaching out!',
        title: 'Thanks for reaching out!',
        body: `<div><p>Our creative team  will contact you as soon as possible to help with your project. 
        Feel free to call us at <b>+1 (305) 702-0180</b> or send us an email to <b>hello@sostereo.com</b> with more details about your search.</p>
        <p>Cheers!</p><br>
        <p><b>Your Contact Email: </b>${email}</p>
          </div>`,
        bodyTitle: 'Help is on the way!',
      };
      this.notificationService.post(notification).subscribe(
        () => {
          this.emailControl.reset();
          this.trackingService.track(
            'Submission Success',
            {
              email: email,
            },
            {
              event_action: 'Landing submission success',
              event_type: 'Form Submission',
              event_user_email: email,
            },
          );
          this.toastr.info("Submitted. We'll get to you soon!");
          this.loadingRequest = false;
          this.responseData = {
            message: "Submitted. We'll get to you soon!",
          };
          setTimeout(() => {
            this.responseData = {};
          }, 5000);
        },
        (err) => {
          this.loadingRequest = false;
          this.emailControl.reset();
          this.toastr.error("We couln't send your request, please try again!");
          this.responseData = {
            message: "We couln't send your request, please try again!",
            error: true,
          };
          setTimeout(() => {
            this.responseData = {};
          }, 5000);
          this.trackingService.track('Submission Error', {
            error: err,
            notificationData: notification,
          });
        },
      );
    }
  }

  public trackEvent(eventName, action, gaParams) {
    this.trackingService.track(
      eventName,
      {
        action: action,
      },
      {
        ...gaParams,
      },
    );
  }
}

import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class CategoryService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(category): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/categories`;
    return this.http.post<any>(url, category);
  }
  query(params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/categories`;
    return this.http.get<any>(url, {params: params});
  }
  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/categories/${id}`;
    return this.http.get<any>(url, {params: params});
  }
  update(id: string, category): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/categories/${id}`;
    return this.http.put<any>(url, category);
  }
  remove(id: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/categories/${id}`;
    return this.http.delete<any>(url);
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: SostereoServicesModule
})
export class NotificationService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(notification): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/notifications`;
    return this.http.post<any>(url, notification);
  }

  collect(notification): Observable<any> {
    const url = `${this.environment.collectRequest}/${this.apiVersion}/collect`;
    return this.http.post<any>(url, notification);
  }

  /*{
    "email": "felipe+unitBla2@sostereo.com",
    "subject": "brief submission",
    "title": "Brief Submission",
    "body": "brief <a href=\"http://0.0.0.0:4200/admin/tags/edition\">Visit SoStereo</a>",
    "bodyTitle": "Hi Felipe",
    "buttonUrl": "http://0.0.0.0:4200/admin/tags/edition",
    "buttonTitle": "Visit SoStereo"
}*/
}

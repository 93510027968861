<div id="artistSliderWrapper" [ngClass]="{'brief-form-wrapper': briefForm}">
  <div class="shadow-shape-bottom-left hidden-for-mobile"> </div>
  <div style="position: relative;" *ngIf="item && briefForm">
    <div class="darker-overlay shadow-shape-bottom-left"></div>
  </div>
  <div [@inOutAnimation] class="sostereo-side pull-left" [ngClass]="{'hidden-for-mobile': !briefForm, 'img-brief-wrapper': briefForm}"
    style="background-size: cover; background-position-x: 60%; background-image: url({{item?.background.src}});">
    <div class="row ml0 mr0 topImage" *ngIf="!briefForm">
      <img *ngIf="isSostereoTenant"
         src="./assets/images/logo.svg"
         [ngClass]="{'mt20': briefForm}"
         alt="SoStereo Logo" class="logo" (click)="goTo('/')" />
    </div>
    <div class="row ml0 mr0 lowImage">
      <div *ngIf="isSostereoTenant" class="col-xs-12 pl30">
        <h3 class="color-light mt40 mb40" *ngIf="!briefForm">Real Music
          <br>
          By Real Artists.
          <br>
          Really Fast &
          <br>
          Ready To Go
        </h3>
        <div [ngClass]="{'brief-text-wrapper': briefForm}">
          <div *ngIf="item && briefForm" class="pb20 brief-container">
            <div class="row ml0 mr0 brief-logo">
              <img src="./assets/images/logo.svg" alt="SoStereo Logo"
                class="logo mt20" (click)="goTo('/')" />
            </div>
            <div class="info-text">
              <div>
                <h1 class="mb10">Finally, Artists Making their Music Accessible for Ads/Content, Fast & Affordably</h1>
                <ul>
                  <li>Thousands of Artists making their Music One-Stop for commercial uses via SoStereo</li>
                  <li>Same-day Complimentary Music Pulls from our Award Winning Creatives</li>
                  <li>No Commitment! Only pay if you license the song.</li>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="item" class="mb30" style="cursor: pointer; min-height:80px;"
            [ngClass]="{'brief-artist-info': briefForm}"
            (click)="goToSlide(item)">
            <h4 class="slider-item-title-alt mt10" style="text-transform: uppercase;">{{ item?.name }}</h4>
            <div *ngIf="item?.metrics?.spotify?.monthlyListeners" class="slide-metrics">
              <span class="mt10 mb10" style="display: flex; align-items: center;">
                <img class="metrics-icon mr5" src="./assets/images/spotify-icon/spotify.svg" style="width: 20px;">
                <b class="slide-metrics-title mr5">{{ numberToLetterFormat(item.metrics?.spotify?.monthlyListeners) }}
                </b>
                <p class="slide-metrics-subtitle mb0">{{ item.metrics?.spotify?.monthlyListeners ? ' Monthly Listeners' :
                  '' }}
                </p>
              </span>
    
            </div>
            <h6 class="slide-link">ARTIST PROFILE></h6>
          </div>
        </div>
      </div>
      <div *ngIf="!isSostereoTenant" class="col-xs-12 ml20">
        <h3 class="color-light mt40 mb40" style="max-width: 30vw;">
          {{company?.theme?.pageDescription}}
        </h3>
      </div>
  
    </div>
  </div>
</div>
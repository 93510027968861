import {Deserializable} from './deserializable';

export class SongModel implements Deserializable {
  _id?: string;
  name: string;
  status: string;
  artists: any[];
  tierId: string;
  editor?: {
    id: string;
    name: string;
  };
  lyric?: {
    content: string;
  };
  rateStatus?: string;
  ratedDate?: string;
  copyright: string;
  tags?: string[];
  labels?: string[];
  fileUrls?: {
    original?: {
      hq?: string;
      mp3?: string;
    };
    instrumental?: {
      hq?: string;
      mp3?: string;
    };
    tv?: {
      hq?: string;
      mp3?: string;
    }
    typeSong: number;
  };
  rightsholders: [{
    text: string;
    type: string;
    percentage: string;
    pro: string;
  }];
  playlists?: string[];
  waveformUrl?: string;
  qualityOfSong?: number;
  qualityOfPerformance?: number;
  qualityOfRecording?: number;
  restriction?: string;
  explicit?: boolean;
  contract?: {
    catalogNumber?: string;
    signedDate?: string;
    expirationDate?: string;
    term?: string;
    split?: string;
    exclusivity?: boolean;
    comments?: string;
    name?: string;
    requiredApproval?: boolean;
  };
  contact?: {
    name?: string;
    email?: string;
    phoneNumber?: string;
    role?: string;
    comments?: string;
  };
  recordingLocation?: {
    city?: string;
    country?: string;
  };
  releaseDate?: string;
  releaseStatus?: string;
  isrc?: string;
  affiliate?: {
    name?: string;
    email?: string;
    phoneNumber?: string;
    percentage?: number;
  };
  availableMarkets: {
    includes: string[];
    excludes: string[];
  };

  deserialize(input: any): this {
    input.explicit = (input.explicit === 'true' || input.explicit === true);
    if (input.contract) {
      input.contract.exclusivity = input.contract.exclusivity !== null ? (input.contract.exclusivity === 'true' || input.contract.exclusivity === true) : null;
      input.contract.requiredApproval = input.contract.requiredApproval !== null ? (input.contract.requiredApproval === 'true' || input.contract.requiredApproval === true) : null;
    }
    input.originalName = input.name;
    return Object.assign(this, input);
  }
}


import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomEncoder } from '../custom.encoder.service';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class IdentityService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/identities`;
    const encodeParams = new HttpParams({ encoder: new CustomEncoder(), fromObject: params });
    return this.http.get<any>(url, { params: encodeParams });
  }

  post(user): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/identities`;
    return this.http.post<any>(url, user);
  }

  addPolicyToUser(userId, policy): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/identities/${userId}/policies`;
    return this.http.post<any>(url, policy);
  }

  removePolicyFromUser(userId, policySrn): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/identities/${userId}/policies/${policySrn}`;
    return this.http.delete<any>(url);
  }

  update(id: string, identity: any): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/identities/${id}`;
    return this.http.put(url, identity);
  }

  getScopes(): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/identities/me`;
    return this.http.get<any>(url);
  }

  createInvitationToken(body): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/invites`;
    return this.http.post<any>(url, body);
  }

  getUserInvitations(body): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/invites`;
    return this.http.get<any>(url, { params: body });
  }

  getUserInvitation(id): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/invites/${id}`;
    return this.http.get<any>(url);
  }

  deleteUserInvitation(id): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/invites/${id}`;
    return this.http.delete<any>(url);
  }

  useInvitation(id: string): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/invites/${id}/accept`;
    return this.http.put(url, {});
  }
}

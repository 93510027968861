import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [],
})
export class SostereoServicesModule {
  public static forRoot(environment: any): ModuleWithProviders<SostereoServicesModule> {
    return {
      ngModule: SostereoServicesModule,
      providers: [
        {
          provide: 'environment', // Use InjectionToken
          useValue: environment,
        },
      ],
    };
  }
}

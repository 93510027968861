import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Album} from '../../models/album.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient,
              @Inject('environment') private environment) {
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/albums`;
    return this.http.get(url, {params: params})
      .pipe(map((res) => {
        return {
          items: res['data'].items.map((album: Album) => new Album().deserialize(album)),
          paging: res['data'].paging
        };
      }));
  }

  save(album): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/albums`;
    return this.http.post<any>(url, album);
  }

  bulk(songs: any[]): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/albums/`;
    return this.http.put<any>(url, songs);
  }
}

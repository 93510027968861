<div id="tags-selection">
  <div class="col-sm-12 col-md-12 col-lg-12 search-tags">
    <div class="tags-list">
      <ul class="pl0">
        <ng-container *ngFor="let tag of tags; let i = index">
          <li class="overflow-tag" *ngIf="i <= tagIndexLimit">
            <a class="tag-link" (click)="selectTag(tag)">
              {{tag.label}}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <button (click)="requestLessTags()" *ngIf="tagIndexLimit > 40"
            class="btn btn-tag">Less Tags
    </button>
    <button (click)="requestMoreTags()" *ngIf="tags.length > tagIndexLimit"
            class="btn btn-tag">More Tags
    </button>
  </div>
</div>

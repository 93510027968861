import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { refsArray, currentReferences } from '../config/common-refs.config';

@Component({
  selector: 'app-common-refs',
  templateUrl: './common-refs.component.html',
  styleUrls: ['./common-refs.component.scss'],
})
export class CommonRefsComponent implements OnInit {
  public refsArray: any = [];
  public url = `${window.location.host}${'/references/2024'}`;

  constructor(
    private clipBoardService: ClipboardService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getReferences();
  }

  private getReferences() {
    this.refsArray = currentReferences || refsArray;
  }

  getShareLink() {
    this.clipBoardService.copy(`${this.url}`);
    this.toastr.info('References link copied to clipboard');
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: SostereoServicesModule
})
export class PolicyService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  get(id: any): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/policies/${id}`;
    return this.http.get<any>(url);
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/policies`;
    return this.http.get<any>(url, {params: params});
  }

  update(id: any, policy): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/policies/${id}`;
    return this.http.put<any>(url, policy);
  }

  post(policy): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/policies`;
    return this.http.post<any>(url, policy);
  }

  delete(id: string) {
    const url = `${this.apiHost}/iam/${this.apiVersion}/policies/${id}`;
    return this.http.delete<any>(url);
  }

  postTemplate(policyTemplate): Observable<any> {
    const url = `${this.apiHost}/iam/${this.apiVersion}/policies/template`;
    return this.http.post<any>(url, policyTemplate);
  }
}

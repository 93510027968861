import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Artist} from '../../models/artist.model';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';

@Injectable({
  providedIn: 'root'
})
export class RestrictionsService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient,
              @Inject('environment') private environment) {

  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/restrictions`;
    return this.http.get<any>(url, {params: params});
  }

  post(restriction): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/restrictions`;
    return this.http.post<any>(url, restriction);
  }

  bulk(restrictionsArray: any): Observable<Artist> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/restrictions`;
    return this.http.put<Artist>(url, restrictionsArray);
  }

  delete(id: string) {
    const url = `${this.apiHost}/organization/${this.apiVersion}/restrictions/${id}`;
    return this.http.delete(url);
  }
}

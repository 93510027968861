import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class ProjectsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(project): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces`;
    return this.http.post<any>(url, project);
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces/${id}`;
    return this.http.get<any>(url, {params: params});
  }

  getSpaceSubmission(token: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaceSubmission/${token}`;
    return this.http.get<any>(url);
  }

  update(id: string, project): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces/${id}`;
    return this.http.put<any>(url, project);
  }

  delete(id: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces/${id}`;
    return this.http.delete<any>(url);
  }

  getReviewInfo(sharedToken: string): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/shared/${sharedToken}`;
    return this.http.get<any>(url);
  }

  addTrackToSpace(spaceId, track): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces/${spaceId}/track/${track.trackId}`;
    return this.http.post(url, track);
  }

  inviteCollaborator(spaceId, body): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/spaces/${spaceId}/collaborators`;
    return this.http.post(url, body);
  }
}

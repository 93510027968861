import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TierModel} from '../../models/tier.model';

@Injectable({
  providedIn: SostereoServicesModule
})
export class TiersService {
  private apiHost = this.environment.apiHost;
  private corePath = this.environment.corePath;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tiers`;
    return this.http.get<any>(url, {params: params});
  }

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tiers/${id}`;
    return this.http.get<any>(url, {params: params});
  }

  post(tier: TierModel): Observable<TierModel> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tiers`;
    return this.http.post<any>(url, tier);
  }

  update(id: string, tier: TierModel): Observable<TierModel> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tiers/${id}`;
    return this.http.put<any>(url, tier);
  }

  delete(tierId: string) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/tiers/${tierId}`;
    return this.http.delete(url);
  }
}

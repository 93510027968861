import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SlidersModel } from './sliders.model';
import { TierModel } from '../../models/tier.model';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class SlidersService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/sliders/${id}`;
    return this.http.get<any>(url);
  }

  post(slider: any): Observable<any> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/sliders/`;
    return this.http.post<any>(url, slider);
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/sliders`;
    return this.http.get<any>(url, { params: params });
  }

  update(id: string, slider: any): Observable<SlidersModel> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/sliders/${id}`;
    return this.http.put<any>(url, slider);
  }

  getSliders(params: any): Observable<SlidersModel> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/sliders`;
    return this.http.get<SlidersModel>(url, { params: params });
  }
}

import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class CompanyService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getByIdentity(): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/companies/identity`;
    return this.http.get<any>(url);
  }

  getBySlug(slug: string, params?: any): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/companies/${slug}`;
    return this.http.get<any>(url, { params: params });
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/companies`;
    return this.http.get<any>(url, { params: params });
  }

  update(id: string, company): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/companies/${id}`;
    return this.http.put<any>(url, company);
  }

  create(company): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/companies`;
    return this.http.post<any>(url, company);
  }

  getSonicData(token, slug: string, params?: any): Observable<any> {
    const url = `${this.apiHost}/organization/${this.apiVersion}/companies/${slug}/signedUrl`;
    return this.http.get<any>(url, {
      headers: { Authorization: `Bearer ${token}`, Origin: 'https://qa.sostereo.com' },
    });
  }
}

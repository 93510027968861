import { OnInit, Input, Output, Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tags-input-dropdown',
  templateUrl: './tags-input-dropdown.component.html',
  styleUrl: './tags-input-dropdown.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsInputDropdownComponent implements OnInit {
  @Input()
  public options;

  @Input()
  public placeholder;

  @Input()
  public uniqueOption;

  @Input()
  public currentSelections;

  @Input()
  public preventValues;

  @Output()
  public tagAdded = new EventEmitter();

  @Output()
  public tagRemoved = new EventEmitter();

  public tags = [];
  public openDropdown = false;
  public typedData = '';

  ngOnInit() {
    if (
      this.currentSelections?.length > 0 &&
      this.currentSelections.every((cs) => cs !== undefined)
    ) {
      console.log('Predefined selection >>> ', this.currentSelections);
      this.tags = this.currentSelections;
    }
  }

  addTagFromOption(option: any) {
    const tagInput = document.querySelector('.tag-input') as HTMLInputElement;
    this.typedData = tagInput.value.trim();

    const duplicatedTag = this.tags.find((t) => t.toLowerCase() === option.name.toLowerCase());
    if (duplicatedTag) {
      return;
    }
    if (option.id === 'custom') {
      this.typedData = '';
    }
    if (this.uniqueOption) {
      this.openDropdown = false;
    }

    if (this.uniqueOption) {
      this.tags = [option.name];
    } else {
      this.tags.push(option.name);
    }
    this.tagAdded.emit(option);
    tagInput.value = '';
    this.options.map((op) => (op.visible = true));
  }

  addTag() {
    const tagInput = document.querySelector('.tag-input') as HTMLInputElement;
    const tagName = tagInput.value.trim();
    if (tagName !== '') {
      this.tags.push(tagName);
      tagInput.value = '';
    }
    this.tagAdded.emit(tagName);
  }

  removeTag(tag: string) {
    this.tags = this.tags.filter((t) => t !== tag);
    this.tagRemoved.emit(tag);
  }

  onSelectChange(input) {
    const filterValue = input.target.value.toLowerCase();
    this.options.forEach((option) => {
      option.visible = option.name.toLocaleLowerCase().includes(filterValue);
    });
  }

  preventValue(evt) {
    if (this.preventValues.includes(evt.key)) {
      evt.preventDefault();
    }
  }
}

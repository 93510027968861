import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var safari, window;
import * as JZip from '@zip.js/zip.js';
import { Observable, Subject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class FileService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;
  private downloadFiles: any = {};
  public $_downloadEvent = new Subject<any>();
  headers = new HttpHeaders({ 'ngsw-bypass': '' });

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  createFile(upload): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/files`;
    return this.http.post<any>(url, upload);
  }

  uploadFileAWSS3(
    url,
    file,
    fields,
    privateFile?: boolean,
    handleError?: boolean,
  ): Observable<any> {
    const formData: FormData = new FormData();
    for (const field in fields) {
      formData.append(field, fields[field]);
    }
    if (!privateFile) {
      formData.append('acl', 'public-read');
    }

    formData.append('file', file, file.name);

    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      ((p) => p.toString() === '[object SafariRemoteNotification]')(
        !window.safari || safari.pushNotification,
      );

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);

    if (!isSafari) {
      xhr.setRequestHeader('ngsw-bypass', '');
    }

    return new Observable((observer) => {
      xhr.upload.onprogress = (event) => {
        observer.next(event);
      };

      xhr.onload = (event) => {
        console.log('load', xhr.status, event);
        if (xhr.status >= 200 && xhr.status < 300) {
          observer.next({ type: 'success', response: xhr.response, ok: true });
          observer.complete();
        } else {
          observer.next({ type: 'error', response: xhr.response, status: xhr.status });
          observer.error({ type: 'error', status: xhr.status, response: xhr.responseText });
        }
      };

      xhr.onerror = () => {
        observer.next({ type: 'error', response: xhr.response, status: xhr.status });
        observer.error({ type: 'error', status: xhr.status, response: xhr.responseText });
      };

      try {
        xhr.send(formData);
        observer.next({ type: 'start' });
      } catch (err) {
        if (handleError) {
          observer.next({ type: 'error', err });
          observer.complete();
        } else {
          observer.error(err);
        }
      }
    });
  }

  downloadZip(url): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/zip',
      Accept: 'application/zip',
    });
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      headers: headers,
      responseType: 'arraybuffer',
    });
  }

  imageProcessing(awsUrl): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/images/processing`;
    return this.http.post<any>(url, awsUrl);
  }

  query(params?: any) {
    const url = `${this.apiHost}/storage/${this.apiVersion}/files`;
    return this.http.get<any>(url, { params: params });
  }

  uploadToken(data, options?): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/uploadTokens`;
    return this.http.post(url, {
      moduleName: data.moduleName,
      resourceType: data.resourceType,
      fileFolder: data.fileFolder || '',
      options: options || {},
    });
  }

  deleteFile(fileId: string): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/files/${fileId}`;
    return this.http.delete(url);
  }

  getSignedFile(fileId: String): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/files/${fileId}/signedUrl `;
    return this.http.get(url);
  }

  downloadFileByUrl(urlFile: string, projectName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(urlFile, { responseType: 'blob' }).subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.target = '_blank';
          a.href = url;
          a.download = `${projectName}`;
          a.click();
          a.remove();
          resolve(true);
        },
        (err) => {
          console.log(err);
          reject(false);
        },
      );
    });
  }

  viewFileByUrl(urlFile: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(urlFile, { responseType: 'blob' }).subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.target = '_blank';
          a.href = url;
          a.click();
          resolve(true);
        },
        (err) => {
          console.log(err);
          reject(false);
        },
      );
    });
  }

  getBlobFileByUrl(urlFile: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(urlFile, { responseType: 'blob', headers: this.headers }).subscribe({
        next: (res) => {
          const file = new Blob([res], { type: 'audio/mpeg' });
          const fileURL = URL.createObjectURL(file);

          resolve(fileURL);
        },
        error: (err) => {
          console.log(err);
          reject(null);
        },
      });
    });
  }

  getBlobFile(urlFile: string, rejectError: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(urlFile, { responseType: 'blob' }).subscribe(
        (res) => {
          const file = new Blob([res]);
          resolve(file);
        },
        (err) => {
          console.log(err);
          if (rejectError) {
            reject(null);
          } else {
            resolve(null);
          }
        },
      );
    });
  }

  generateZipFile(content: Blob, nameFile: string) {
    const url = window.URL.createObjectURL(content);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.target = '_blank';
    a.href = url;
    a.download = `${nameFile}`;
    a.click();
    a.remove();
  }

  public async downloadZipFile(files: any[], id: any, nameFile: string) {
    this.downloadFiles[id] = { completeFiles: 0 };
    const zip = new JZip.ZipWriter(new JZip.BlobWriter('application/zip'), { bufferedWrite: true });
    of(...files.map((file) => this.getBlobSingleFile(file, id)))
      .pipe(mergeMap((obs) => obs, 5))
      .subscribe({
        next: (res) => {
          if (res?.content) {
            zip.add(res.name, new JZip.BlobReader(res.content));
            this.downloadFiles[id].completeFiles++;
            this.$_downloadEvent.next({
              id,
              type: 'addFile',
              completeFiles: this.downloadFiles[id].completeFiles,
              message: `Preparing Files ${this.downloadFiles[id].completeFiles}/${files.length}`,
            });
          }
        },
        complete: async () => {
          try {
            this.$_downloadEvent.next({ id, type: 'zipping', message: `Zipping file` });
            const content = await zip.close();
            this.$_downloadEvent.next({ id, type: 'complete', message: '' });
            this.generateZipFile(content, nameFile);
          } catch (e) {
            this.$_downloadEvent.next({ id, type: 'ErrorZip' });
            console.log(e);
          }
          delete this.downloadFiles[id];
        },
      });
  }

  public getBlobSingleFile(file: any, id: string): Observable<any> {
    return new Observable((observable) => {
      this.getSignedFile(file.id).subscribe(
        (data) => {
          const { signedUrl } = data.data;
          this.getBlobFile(signedUrl)
            .then((fileBlob) => {
              observable.next({ name: file.name, content: fileBlob });
              observable.complete();
            })
            .catch((err) => {
              this.reportErrorFile(file, err, id);
              observable.next(null);
              observable.complete();
            });
        },
        (err) => {
          this.reportErrorFile(file, err, id);
          observable.next(null);
          observable.complete();
        },
      );
    });
  }

  private reportErrorFile(file: any, err: any, id: string) {
    this.$_downloadEvent.next({ id, type: 'ErrorFile', error: err, file: file });
  }

  public invalidateCloudFrontCache(cloudFrontUrl: string): Observable<any> {
    const url = `${this.apiHost}/storage/${this.apiVersion}/files/invalidateCloudFrontCache`;
    return this.http.post(url, {
      s3Url: cloudFrontUrl,
    });
  }

  public getExtensionFile(nameFile: string) {
    const file = nameFile.split('.');
    return file[file.length - 1];
  }
}

<section id="multiselectContainer" (clickOutside)="dropDownOpen=false;firstQuery = false;">
  <input 
      data-hj-allow
      class="form-control"  
      type="text"
      placeholder="Search"
      (keyup)="onSearch($event)"
      [(ngModel)]="mutiselectTyped"
      (click)="dropDownOpen=true;openOptions()">
  <div *ngIf="dropDownOpen" class="multiselect-wrapper">
    <p *ngIf="dropdownLoading" class="info-text">Loading data <i class="spin medium loader-margin ml5"></i></p>
    <p *ngIf="noResults && !dropdownLoading" class="info-text">There are no results</p>
    <ng-container *ngIf="!dropdownLoading">
      <ng-container *ngFor="let value of data">
        <div id="multiselectOptionsContainer" (click)="!checkOptions? selectOrDeselctItem(value) : ''">
          <div class="pl10 pr10 pt5 pb5" [ngClass]="{'inactive-color': checkOptions && !value.check}">
            <ng-container *ngFor="let option of options">
              <input type="checkbox" *ngIf="option.editionDataType === 'checkbox'" [(ngModel)]="value[option.field]"
                (click)="$event.stopPropagation()" (change)="updateModel(value, option.field, true)">
              <p *ngIf="option.editionDataType === 'text'" class="category-title">{{value[option.field]}}</p>
              <input type="date" *ngIf="option.editionDataType === 'inputDate' && (checkOptions && value.check)"
                [(ngModel)]="value[option.field]" (change)="$event.stopPropagation(); updateModel(value, option.field)"
                     [ngClass]="{'inactive-color': checkOptions && !value.check}"
                     style="height: 19px; margin-right: 10px;">
              <input type="text" *ngIf="option.editionDataType === 'input' && (checkOptions && value.check)"
                [(ngModel)]="value[option.field]"
                     (keyup)="$event.stopPropagation(); updateModel(value, option.field)" placeholder="{{option.label || ''}}">
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="loadMore" class="pt5 mb5" (click)="getMoreData()">
      <p class="secondary-color fw600" style="display: block;">
        LOAD MORE RESULTS
      </p>
    </div>
  </div>
</section>
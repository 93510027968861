import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class LicenseService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(license: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/licenseRequest`;
    return this.http.post(url, license);
  }
}

/*
 * Public API Surface of sostereo-services
 */

// Artist service
export * from './lib/artist/artist.service';
// Album service
export * from './lib/album/album.service';
// Artist model
export * from './models/artist.model';
// Auth service
export * from './lib/auth/authentication.service';
// Category service
export * from './lib/category/category.service';
// Contact us service
export * from './lib/contact-us/contact-us.service';
// Company us service
export * from './lib/company/company.service';
// Like service
export * from './lib/like/like.service';
// Playlist service
export * from './lib/playlists/playlists.service';
// Playlists StereoSpaces service
export * from './lib/playlists-stereospaces/playlists-stereospaces.service';
// SignUp service
export * from './lib/sign-up/sign-up.service';
// Sliders service
export * from './lib/sliders/sliders.model';
export * from './lib/sliders/sliders.service';
// Transactions service
export * from './lib/transactions/transactions.service';
// Transfers service
export * from './lib/transfers/transfers.service';
// User service
export * from './lib/user/user.service';
// YouTube service
export * from './lib/youtube/youtube.service';
// Projects service
export * from './lib/projects/projects.service';
// Ingestions service
export * from './lib/ingestions/ingestions.service';
// Submission service
export * from './lib/submission/submission.service';
// File service
export * from './lib/file/file.service';
// Identity service
export * from './lib/identity/identity.service';
// Comments service
export * from './lib/comments/comments.service';
// playerwidget service
export * from './lib/waveform/waveform.service';
// Policy Service
export * from './lib/policy/policy.service';
// Groups Service
export * from './lib/groups/groups.service';
// Subscriptions Service
export * from './lib/subscriptions/subscriptions.service';
// Tiers Service
export * from './lib/tiers/tiers.service';
// Tier model
export * from './models/tier.model';
// Tags Service
export * from './lib/tags/tags.service';
// Songs Service
export * from './lib/songs/songs.service';
// Song model
export * from './models/song.model';
// Markets Service
export * from './lib/markets/markets.service';
// Segments Service
export * from './lib/segments/segments.service';
// Notification Service
export * from './lib/notification/notification.service';
// Tags-rs Service
export * from './lib/tags-rs/tags-rs.service';
// Tags-categories Service
export * from './lib/tags-categories/tags-categories.service';
// Search Service
export * from './lib/search/search.service';
// License Service
export * from './lib/license/license.service';
// Metrics Service
export * from './lib/metrics/metrics.service';
// Agreement Service
export * from './lib/agreement/agreement.service';
// Restrictions Service
export * from './lib/restrictions/restrictions.service';
// Inquiries Service
export * from './lib/inquiries/inquiries.service';
// Feeds Service
export * from './lib/feeds/feeds.service';
// ddex ingestions Service
export * from './lib/ddex/ddex.service';
// Root module
export * from './lib/sostereo-services.module';
// Embeds service
export * from './lib/embeds/embeds.service';
//  locations Service
export * from './lib/locations/locations.service';
//  Ads Service
export * from './lib/ads/ads.service';
export * from './models/ads.model';

import { Component, OnInit, ChangeDetectionStrategy, Input, signal } from '@angular/core';

@Component({
  selector: 'app-count-animation',
  templateUrl: './count-animation.component.html',
  styleUrls: ['./count-animation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountAnimationComponent implements OnInit {
  @Input() target: number = 0;
  @Input() init: number = 0;
  @Input() customCountUp: number = 20;
  public number = signal(this.init);

  ngOnInit(): void {
    setTimeout(() => this.countUp(), 0);
  }

  countUp() {
    if (this.target - 100 > this.number()) {
      this.number.set(this.number() + 100);
    } else {
      this.number.set(this.number() + 1);
    }
    if (this.number() > this.target) {
      return;
    }
    setTimeout(() => this.countUp(), this.customCountUp);
  }
}

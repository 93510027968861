import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SostereoServicesModule } from '../sostereo-services.module';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class SignUpService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getUserBySignUpToken(token): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/account-sign-up/${token}`;
    return this.http.get<any>(url);
  }

  userSignUp(user) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/user-sign-up`;
    return this.http.post<any>(url, user);
  }

  signUp(user): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/sign-up`;
    return this.http.post<any>(url, user);
  }

  inviteMs(user): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/invite-ms`;
    return this.http.post<any>(url, user);
  }
}

import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { capitalize } from 'lodash-es';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
declare var window;

@Injectable()
export class SeoService {
  public updated = false;
  defaultSeoData = {
    title: this.environment?.title || 'SoStereo: Real music by real artists, real fast and easy.',
    description:
      'Curated music solutions with award-winning artists for brands, agencies, films and more.',
    bg: 'https://img-sostereo.sostereo.com/sostereo-images-production/seo_banner@small.jpg',
    site: 'SoStereo: Real music by real artists, real fast and easy.',
    url: 'https://www.sostereo.com',
  };

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router,
    @Inject(DOCUMENT) private dom,
    @Inject('environment') private environment,
  ) {}

  createTags(data?, url?: string) {
    if (!this.updated) {
      this.title.setTitle(data?.pageTitle || this.environment.title || this.defaultSeoData.title);
      this.meta.addTag({
        name: 'description',
        content:
          data?.pageDescription || this.environment.description || this.defaultSeoData.description,
      });

      // Open Graph Facebook
      this.meta.addTag({ property: 'fb:app_id', content: '773450549401911' });
      this.meta.addTag({
        property: 'og:title',
        content: data?.pageTitle || this.environment.title || this.defaultSeoData.title,
      });
      this.meta.addTag({ property: 'og:type', content: 'website' });
      this.meta.addTag({
        property: 'og:url',
        content: `https://${window.location.hostname}` || this.defaultSeoData.url,
      });
      this.meta.addTag({
        property: 'og:image',
        content: data?.bg || this.environment.seoBg || this.defaultSeoData.bg,
      });
      this.meta.addTag({ property: 'og:image:type', content: 'image/jpg' });
      this.meta.addTag({ property: 'og:image:width', content: '' });
      this.meta.addTag({ property: 'og:image:height', content: '' });
      this.meta.addTag({
        property: 'og:description',
        content:
          data?.pageDescription || this.environment.description || this.defaultSeoData.description,
      });
      this.meta.addTag({
        property: 'og:site_name',
        content: data?.tenantId ? capitalize(data?.tenantId) : this.defaultSeoData.site,
      });

      // Twitter Cards
      this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
      this.meta.addTag({
        name: 'twitter:site',
        content:
          data?.tenantId && data?.tenantId !== 'sostereo'
            ? capitalize(data?.tenantId)
            : '@SoStereo',
      });
      this.meta.addTag({
        name: 'twitter:creator',
        content:
          data?.tenantId && data?.tenantId !== 'sostereo'
            ? capitalize(data?.tenantId)
            : '@SoStereo',
      });
      this.meta.addTag({
        name: 'twitter:title',
        content: data?.tenantId || this.environment.title || this.defaultSeoData.title,
      });
      this.meta.addTag({
        name: 'twitter:description',
        content:
          data?.pageDescription || this.environment.description || this.defaultSeoData.description,
      });
      this.meta.addTag({
        name: 'twitter:image',
        content: data?.bg || this.environment.seoBg || this.defaultSeoData.bg,
      });
    }
    const urls = [
      '/music-sync-licensing/playlist',
      '/music-sync-licensing/artist',
      '/music-sync-licensing/edit-playlist',
      '/our-work/detail/',
    ];
    if (!urls.some((urlPlayer) => url?.includes(urlPlayer))) {
      window.prerenderReady = true;
    }
  }

  updateMeta(data, tenantId?) {
    this.title.setTitle(data.pageTitle || this.defaultSeoData.title);
    this.meta.updateTag({
      name: 'description',
      content: data.pageDescription || this.defaultSeoData.description,
    });

    // Open Graph Facebook
    this.meta.updateTag({
      property: 'og:title',
      content: data.pageTitle || this.defaultSeoData.title,
    });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:url', content: window.location.href + (data.url || '') });
    this.meta.updateTag({
      property: 'og:image',
      content: data.pageBackground || this.defaultSeoData.bg,
    });
    this.meta.updateTag({ property: 'og:image:type', content: 'image/jpg' });
    this.meta.updateTag({ property: 'og:image:width', content: data.imageWidth || '' });
    this.meta.updateTag({ property: 'og:image:height', content: data.imageHeight || '' });
    this.meta.updateTag({
      property: 'og:description',
      content: data.pageDescription || this.defaultSeoData.description,
    });
    this.meta.updateTag({
      property: 'og:site_name',
      content: tenantId && tenantId !== 'sostereo' ? capitalize(tenantId) : 'SoStereo',
    });

    // Twitter Cards
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: tenantId && tenantId !== 'sostereo' ? capitalize(tenantId) : '@SoStereo',
    });
    this.meta.updateTag({
      name: 'twitter:creator',
      content: tenantId && tenantId !== 'sostereo' ? capitalize(tenantId) : '@SoStereo',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: data.pageTitle || this.defaultSeoData.title,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: data.pageDescription || this.defaultSeoData.description,
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: data.pageBackground || this.defaultSeoData.bg,
    });
  }

  updateBasicSeo(data) {
    this.updated = true;

    let canonicalRoute = this.environment.redirectAppUrl + this.router.url;
    canonicalRoute = canonicalRoute.split('?')[0];
    if (!this.router.url.includes('/loading')) {
      this.updateCanonicalUrl(canonicalRoute);
    }
    this.title.setTitle(data.pageTitle || this.defaultSeoData.title);
    this.meta.updateTag({
      name: 'description',
      content: data.pageDescription || this.defaultSeoData.description,
    });

    this.meta.updateTag({
      property: 'og:title',
      content: data.pageTitle || this.defaultSeoData.title,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: data.pageDescription || this.defaultSeoData.description,
    });
    this.meta.updateTag({ property: 'og:url', content: window.location.href + (data.url || '') });

    this.meta.updateTag({
      name: 'twitter:title',
      content: data.pageTitle || this.defaultSeoData.title,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: data.pageDescription || this.defaultSeoData.description,
    });
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  addNotFollowTag() {
    this.meta.addTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  updateImageSeo(data) {
    this.updated = true;
    this.meta.updateTag({
      property: 'og:image',
      content: data.pageBackground || this.defaultSeoData.bg,
    });
    this.meta.updateTag({ property: 'og:image:width', content: data.imageWidth || '' });
    this.meta.updateTag({ property: 'og:image:height', content: data.imageHeight || '' });

    this.meta.updateTag({
      name: 'twitter:image',
      content: data.pageBackground || this.defaultSeoData.bg,
    });
  }

  updateWindowPrerender(flag: boolean) {
    window.prerenderReady = flag;
  }
}

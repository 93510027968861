export interface Ads {
  data: Data;
}

export interface Data {
  items: Ad[];
  paging: Paging;
}

export interface Ad {
  _id: string;
  String: String;
  categories: String[];
  createdAt: Date;
  description: string;
  fileType: String;
  groups: any[];
  metadata: Metadata;
  publishedAt: Date;
  s3Url: string;
  source: Source;
  sourceId: string;
  status: Status;
  tags: Tags[];
  tagsSource: TagsSource;
  tenantId: TenantID;
  thumbUrl: string;
  title: string;
  updatedAt: Date;
  url: string;
}

export interface Tags {
  score: number;
  label: string;
  slug: string;
  overThreshold: boolean;
}

export interface Metadata {
  adArchiveID: string;
  adid: string;
  archiveTypes: any[];
  categories: number[];
  collationCount: number;
  collationID: number;
  containsDigitallyCreatedMedia: boolean;
  containsSensitiveContent: boolean;
  currency: string;
  endDate: number;
  entityType: EntityType;
  fevInfo: null;
  gatedType: GatedType;
  hasUserReported: boolean;
  hiddenSafetyData: boolean;
  hideDataStatus: HideDataStatus;
  impressionsWithIndex: ImpressionsWithIndex;
  isAAAEligible: boolean;
  isActive: boolean;
  isProfilePage: boolean;
  menuItems: any[];
  pageID: string;
  pageInfo: null;
  pageIsDeleted: boolean;
  pageName: String;
  politicalCountries: any[];
  publisherPlatform: Source[];
  reachEstimate: null;
  reportCount: null;
  snapshot: Snapshot;
  spend: null;
  startDate: number;
  stateMediaRunLabel: null;
}

export enum EntityType {
  PersonProfile = 'person_profile',
}

export enum GatedType {
  Eligible = 'eligible',
}

export enum HideDataStatus {
  None = 'NONE',
}

export interface ImpressionsWithIndex {
  impressionsIndex: number;
  impressionsText: null;
}

export enum Source {
  Facebook = 'facebook',
  Instagram = 'instagram',
}

export interface Snapshot {
  ad_creative_id: string;
  additional_info: null;
  body: Body;
  branded_content: null;
  brazil_tax_id: null;
  byline: null;
  caption: Caption | null;
  cards: Card[];
  country_iso_code: null;
  creation_time: number;
  cta_text: null | string;
  cta_type: CtaType | null;
  current_page_name: String;
  disclaimer_label: null;
  display_format: DisplayFormat;
  dynamic_versions: null;
  ec_certificates: null;
  edited_snapshots: any[];
  effective_authorization_category: HideDataStatus;
  event: any[];
  extra_images: any[];
  extra_links: any[];
  extra_texts: any[];
  extra_videos: any[];
  images: any[];
  instagram_actor_name: String;
  instagram_branded_content: null;
  instagram_handle: string;
  instagram_profile_pic_url: string;
  instagram_shopping_products: any[];
  instagram_url: string;
  is_reshared: boolean;
  link_description: null | string;
  link_url: null | string;
  page_categories: PageCategories;
  page_entity_type: EntityType;
  page_id: number;
  page_is_deleted: boolean;
  page_is_profile_page: boolean;
  page_like_count: number;
  page_name: String;
  page_profile_picture_url: string;
  page_profile_uri: string;
  page_welcome_message: null;
  root_reshared_post: null;
  title: null | string;
  version: number;
  videos: Video[];
}

export interface Body {
  callerHash: CallerHash;
  markup: Markup;
}

export enum CallerHash {
  The1E28D7Ba9D1F28C98A8F2Efc73D54D69 = '1e28d7ba9d1f28c98a8f2efc73d54d69',
}

export interface Markup {
  __html: string;
}

export enum Caption {
  PrincessCOM = 'princess.com',
}

export interface Card {
  body: string;
  caption: Caption;
  cta_text: CtaText;
  cta_type: CtaType;
  image_crops: null;
  link_description: string;
  link_url: string;
  original_image_url: null;
  resized_image_url: null;
  title: string;
  video_hd_url: string;
  video_preview_image_url: string;
  video_sd_url: string;
  watermarked_resized_image_url: null;
  watermarked_video_hd_url: string;
  watermarked_video_sd_url: string;
}

export enum CtaText {
  BookNow = 'Book Now',
  LearnMore = 'Learn More',
}

export enum CtaType {
  BookTravel = 'BOOK_TRAVEL',
  LearnMore = 'LEARN_MORE',
}

export enum DisplayFormat {
  Dco = 'dco',
  Video = 'video',
}

export interface PageCategories {
  '197280796951159': The197280796951159;
}

export enum The197280796951159 {
  CruiseLine = 'Cruise Line',
}

export interface Video {
  video_hd_url: string;
  video_preview_image_url: string;
  video_sd_url: string;
  watermarked_video_hd_url: string;
  watermarked_video_sd_url: string;
}

export enum Status {
  Published = 'published',
}

export enum TagsSource {
  None = 'none',
}

export enum TenantID {
  AdSostereo = 'Ad-sostereo',
}

export interface Paging {
  limit: number;
  page: number;
  totalItems: number;
  totalPages: number;
}

import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class SearchService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getSuggestions(body): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/search/suggestions`;
    return this.http.post(url, body);
  }

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/search/urlsearch`;
    return this.http.get(url, { params: params });
  }

  getRatersQueue(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/search/ratersQueue`;
    return this.http.get(url, { params: params });
  }
}

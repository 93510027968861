import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class MarketsService {
  private apiHost = this.environment.apiHost;
  private corePath = this.environment.corePath;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {

  }

  get(): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/markets`;
    return this.http.get<any>(url);
  }

}

import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ad, Ads } from '../../models/ads.model';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class AdsService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  query(params?: any): Observable<Ads> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/ads`;
    return this.http.get<Ads>(url, { params: params });
  }

  update(id: string, ad): Observable<any> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/ads/${id}`;
    return this.http.put<any>(url, ad);
  }

  bulk(ads: any[]): Observable<any> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/ads/`;
    return this.http.put<any>(url, ads);
  }

  getSignedUrl(adId: string): Observable<any> {
    const url = `${this.apiHost}/discover/${this.apiVersion}/ads/${adId}/signedUrl`;
    return this.http.get<any>(url);
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  post(comment): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/comments`;
    return this.http.post<any>(url, comment);
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/comments`;
    return this.http.get<any>(url, {params: params});
  }

  delete(id): Observable<any> {
    const url = `${this.apiHost}/stereospaces/${this.apiVersion}/comments/${id}`;
    return this.http.delete<any>(url);
  }
}

import { Deserializable } from './deserializable';

export class Artist implements Deserializable {

  items: [{
    _id: string,
    status: string,
    name: string,
    slug: string,
    profileImages: object,
    backgroundImages: string,
    description: string,
    publicAccess: boolean,
    location: object,
    labels: [string],
    genderAndAgeFollowers: [],
    countryFollowers: [],
    facebookConnected: boolean,
    facebookPage: string,
    updatedAt: string,
    createdAt: string,
    twitterUrl: string,
    youtubeUrl: string,
    nextBigSoundUrl: string,
    onboarding: {
      status: string,
      comments: string
    },
    union: boolean,
    genres: [string]
  }];
  paging: {
    limit: number;
    page: number;
    totalItems: number;
    totalPages: number;
  };

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SostereoServicesModule} from '../sostereo-services.module';
import {CustomEncoder} from '../custom.encoder.service';

@Injectable({
  providedIn: SostereoServicesModule
})
export class UserService {

  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users`;
    const encodeParams = new HttpParams({encoder: new CustomEncoder(), fromObject: params});
    return this.http.get<any>(url, {params: encodeParams});
  }

  get(id: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users/${id}`;
    return this.http.get<any>(url);
  }

  post(user: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users`;
    return this.http.post<any>(url, user);
  }

  accountRequest(user: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/accountRequest`;
    return this.http.post<any>(url, user);
  }

  forgotPassword(user: any) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/forgot-password`;
    return this.http.post<any>(url, user);
  }

  resetPassword(token: string, password: string) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/reset-password`;
    return this.http.post<any>(url, {token, password});
  }

  update(id: string, user: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users/${id}`;
    return this.http.put(url, user);
  }

  delete(id: string, body?: any): Observable<any> {
    const options = {
      headers: {},
      body: body
    };
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users${id ? '/' + id : ''}`;
    return this.http.delete(url, options);
  }

  reinvite(id: string, user: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users/${id}/reinvite`;
    return this.http.post<any>(url, user);
  }

  generateToken(userUid: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/generateToken/${userUid}`;
    return this.http.get<any>(url);
  }

  inviteUser(userData): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/users/invite`;
    return this.http.post<any>(url, userData);
  }
}

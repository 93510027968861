import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SongModel } from '../../models/song.model';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class SongsService {
  private apiHost = this.environment.apiHost;
  private corePath = this.environment.corePath;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs`;
    return this.http.get<any>(url, { params: params });
  }

  getFields(): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/fields/song`;
    return this.http.get<any>(url);
  }

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs/${id}`;
    return this.http.get<any>(url, { params: params });
  }

  post(song: SongModel): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs`;
    return this.http.post<any>(url, song);
  }

  postArray(songs: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs`;
    return this.http.post<any>(url, songs);
  }

  update(id: string, song): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs/${id}`;
    return this.http.put<any>(url, song);
  }

  bulk(songs: any[]): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs/`;
    return this.http.put<any>(url, songs);
  }

  delete(songId: string) {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs/${songId}`;
    return this.http.delete(url);
  }

  uploadToken(): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/songs/upload-token`;
    return this.http.post(url, {});
  }
}

<div id="references-container">
  <ng-container *ngFor="let refItem of refsArray; let i = index">
    <div
      class="ref-item"
      id="{{ refItem.id }}"
      [ngClass]="{
        'color-1': (i + 1) % 3 === 1,
        'color-2': (i + 1) % 3 === 2,
        'color-3': (i + 1) % 3 === 0
      }"
      [ngStyle]="{ 'background-color': refItem?.defaultBackGroundColor }"
    >
      <div>
        <h2 [ngStyle]="{ color: refItem.defaultTextColor }">{{ refItem.song }}</h2>
        <p [ngStyle]="{ color: refItem.defaultTextColor }" class="description">
          {{ refItem.genre }}
        </p>
      </div>
      <div class="ref-row">
        <a href="{{ refItem.link }}" target="_blank">
          <img src="{{ refItem.cover }}" alt="cover" />
        </a>
        <div class="ref-col">
          <p [ngStyle]="{ color: refItem?.defaultTextColor }">{{ refItem.description }}</p>
          <a href="{{ refItem.link }}" target="_blank" style="text-decoration: none">
            <p
              [ngStyle]="{ color: refItem.defaultTextColor }"
              class="go-to-box d-flex align-items-center"
            >
              Play reference
              <span class="material-icons" [attr.data-icon]="'arrow_right_alt'"></span>
            </p>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div id="copy-refs" class="d-flex">
  <h1>Share these references</h1>
  <p class="centered-text">
    Do you want to share these references with anyone external / outside your sostereo account?
    <br />
    Use this public link!
  </p>
  <div class="d-flex flex-row align-items-center" (click)="getShareLink()">
    <div class="ref-link d-flex">
      <div class="d-flex flex-col align-items-center urlShare">
        {{ url }}
      </div>
      <span class="d-flex align-items-center">
        Copy Link
        <i
          class="material-icons info-icon ml10"
          aria-hidden="true"
          [attr.data-icon]="'content_copy'"
        ></i>
      </span>
    </div>
  </div>
</div>

import {Inject, Injectable} from '@angular/core';
import {SostereoServicesModule} from '../sostereo-services.module';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: SostereoServicesModule
})
export class FeedService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;

  constructor(private http: HttpClient, @Inject('environment') private environment) { }


  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/feeds/${id}`;
    return this.http.get<any>(url, {params: params});
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/feeds`;
    return this.http.get<any>(url, {params: params});
  }
}

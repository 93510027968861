import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DdexSong } from '../../models/ddex-song.model';

@Injectable({
  providedIn: 'root',
})
export class DdexService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  get(id: string, params: any): Observable<any> {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/ingestions/${id}`;
    return this.http.get<any>(url, { params: params });
  }

  query(params: any): Observable<any> {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/ingestions`;
    return this.http.get<any>(url, { params: params });
  }

  update(id: string, params): Observable<any> {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/ingestions/${id}`;
    return this.http.put<any>(url, params);
  }

  execute(id: string): Observable<any> {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/ingestions/execute/${id}`;
    return this.http.post<any>(url, {});
  }

  getQuerySongs(params: any) {
    const url = `${this.apiHost}/ingestor/${this.apiVersion}/ingestions/songs`;
    return this.http.get<DdexSong>(url, { params: params });
  }
}
